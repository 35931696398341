import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useRef } from "react";

const HowItWorks = () => {
  const scrollAmount = 300;
  const containerRef = useRef(null);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      if (direction === "left") {
        containerRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: "smooth",
        });
      } else if (direction === "right") {
        containerRef.current.scrollTo({
          left: Math.min(scrollLeft + scrollAmount, scrollWidth - clientWidth),
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <section className="py-28 bg-[#FFF5EE] mt-12 hidden md:block">
      <div className="container mx-auto px-0 lg:ml-16 sm:ml-24">
        <div className="text-center md:text-left">
          {" "}
          {/* Added text-center for mobile, left-aligned by default for larger screens */}
          <h2 className="text-4xl font-semibold font-poppins mb-4 text-center mx-auto lg:text-center max-w-screen-lg lg:ml-[185px]">
            How It Works
          </h2>
          <span className="text-gray-400 text-sm font-poppins mb-24 block text-center mx-auto lg:text-center max-w-screen-lg lg:ml-[185px]">
            Our House construction steps are simple and easy to understand: Plan
            - Build - Track - Settle
          </span>
        </div>
        <div className="relative lg:ml-10">
          <div
            className="overflow-x-auto whitespace-nowrap flex gap-8 md:gap-16 scrollbar-hide mt-12 flex-col md:flex-row"
            ref={containerRef}
          >
            {/* Tile 1 */}
            <div className="relative h-[240px] md:h-[290px] w-[300px] md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-8 md:mb-8">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                1
              </div>
              <img
                src="images/how1.png"
                alt="Step 1"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 ml-4 md:ml-16 z-10 relative"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  SITE ASSESSMENT
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyze your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
            {/* Tile 2 */}
            <div className="relative h-[240px] md:h-[290px] w-[300px] md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-8 md:mb-8">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                2
              </div>
              <img
                src="images/how2.png"
                alt="Step 2"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 z-10"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  DESIGN & BLUEPRINT
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyze your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
            {/* Tile 3 */}
            <div className="relative h-[240px] md:h-[290px] w-[300px] md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-8 md:mb-8">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                3
              </div>
              <img
                src="images/how3.png"
                alt="Step 3"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 z-10"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  CONSTRUCTION PHASE
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyze your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
            {/* Tile 4 */}
            <div className="relative h-[240px] md:h-[290px] w-[300px] md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-8 md:mb-8">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                4
              </div>
              <img
                src="images/how4.png"
                alt="Step 4"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 z-10"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  QUALITY CHECK &
                  <br />
                  HANDOVER
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyze your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
          </div>
          <div className="absolute inset-x-0 flex space-x-2 md:bottom-[-80px] lg:bottom-[-100px] justify-center items-center lg:ml-[-167px]">
            <button
              className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => handleScroll("left")}
              aria-label="Scroll left"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="text-sm sm:text-lg"
              />
            </button>
            <button
              className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => handleScroll("right")}
              aria-label="Scroll right"
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-sm sm:text-lg"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
