// src/components/Dashboard.js
import React, { useEffect } from "react";
import { FaCogs, FaBox, FaProjectDiagram, FaEnvelope } from "react-icons/fa";
import { IoNewspaper } from "react-icons/io5";
import axios from "axios";
import { useState } from "react";
import { FaBuilding, FaInfoCircle } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";

const Dashboard = () => {
  const [activeServices, setActiveServices] = useState(0);
  const [activePackages, setActivePackages] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);
  const [activeBanking, setActiveBanking] = useState(0);
  const [activeNews, setActiveNews] = useState(0);
  const [activeFAQs, setActiveFAQs] = useState(0);
  const [activeTTE, setActiveTTE] = useState(0);
  const [activeCustomerT, setActiveCustomerT] = useState(0);
  const [activeInbox, setActiveInbox] = useState(0);

  useEffect(() => {
    const fetchActiveServices = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/api/services");
        setActiveServices(response.data.services.length);
      } catch (error) {
        console.error("Error fetching active services:", error);
      }
    };

    fetchActiveServices();

    const fetchActivePackages = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/api/packages/");
        setActivePackages(response.data.length);
      } catch (error) {
        console.log("Error fetching active packages : ", error);
      }
    };

    fetchActivePackages();

    const fetchActiveProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetch/projects/"
        );
        console.log(response.data);
        setActiveProjects(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveProjects();

    const fetchActiveInbox = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/contact-us/"
        );
        setActiveInbox(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveInbox();

    const fetchActiveBanking = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/bankingpartners/"
        );
        setActiveBanking(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveBanking();

    const fetchActiveNews = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchNews/news"
        );
        setActiveNews(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveNews();

    const fetchActiveFAQs = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/fetchFAQ/faqs");
        setActiveFAQs(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveFAQs();

    const fetchActiveTTE = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/TalkToExpert/"
        );
        setActiveTTE(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveTTE();

    const fetchActiveCustomerT = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchTestimonials/get-testimonials/"
        );
        setActiveCustomerT(response.data.length);
      } catch (error) {
        console.log("Error fetching active projetcs : ", error);
      }
    };

    fetchActiveCustomerT();
  }, []);

  return (
    <div className="flex flex-wrap gap-6 p-6">
      {/* Active Services */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaCogs className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Active Services</h2>
          <p className="text-xl">{activeServices}</p>
        </div>
      </div>

      {/* Active Packages */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaBox className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Active Packages</h2>
          <p className="text-xl">{activePackages}</p>
        </div>
      </div>

      {/* Active Projects */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaProjectDiagram className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Active Projects</h2>
          <p className="text-xl">{activeProjects}</p>
        </div>
      </div>

      {/* Contact Us Messages */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaEnvelope className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Contact Us</h2>
          <p className="text-xl">{activeInbox}</p>
        </div>
      </div>

      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaBuilding className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Banking</h2>
          <p className="text-xl">{activeBanking}</p>
        </div>
      </div>
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <IoNewspaper className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">News</h2>
          <p className="text-xl">{activeNews}</p>
        </div>
      </div>
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaInfoCircle className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">FAQs</h2>
          <p className="text-xl">{activeFAQs}</p>
        </div>
      </div>
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaEnvelope className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Talk to Expert</h2>
          <p className="text-xl">{activeTTE}</p>
        </div>
      </div>
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <IoIosPeople className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Customer Testimonials</h2>
          <p className="text-xl">{activeCustomerT}</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
