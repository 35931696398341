import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Navbar2 } from "../Navbar2";

import NavbarMobile from "../NavbarMobile";
import { Footer } from "../Footer";

import Subservices from "./Subservices";
import { NavbarTab } from "../NavbarTab";

const MainServicePage = () => {
  const { serviceId } = useParams(); // Capture serviceId from the route
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <Subservices />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}{" "}
    </div>
  );
};

export default MainServicePage;
