// src/components/AdminLayout.js
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Dashboard from "./Dashboard";
import ServiceForm from "./ServiceForm";
import ServiceManage from "./ServiceManage";
import AddProjectForm from "./AddProjectForm";
import EditProjectForm from "./EditProjectForm";
import ProjectTable from "./ProjectTable";
import DeleteProject from "./DeleteProject"; // Import the DeleteProject component
import HouseConstructorCalculator from "./HouseConstructorCalculator";
import ActivePackages from "./ActivePackages";
import AddPackage from "./AddPackage";
import ContactUsAdmin from "./ContactUsAdmin";
import AdminTalkToExpert from "./AdminTalkToExpert";
import AddUser from "./AddUser";
import ActiveUser from "./ActiveUser";
import ActiveBankingPartners from "./ActiveBankingPartners";
import AddBankingPartner from "./AddBankingPartner";
import AddPermission from "./AddPermission";
import AddTestimonials from "./AddTestimonials";
import EditTestimonials from "./EditTestimonials";
import DeleteTestimonials from "./DeleteTestimonials";
import AddFAQForm from "./AddFAQForm";
import EditFAQForm from "./EditFAQForm";
import DeleteFAQForm from "./DeleteFAQForm";
import AddNews from "./AddNews";
import DeleteNews from "./DeleteNews";
import EditNews from "./EditNews";
import HomeBanner from "./HomeBanner";
import ProjectsBanner from "./ProjectsBanner";
import ThreeDBanner from "./ThreeDBanner";
import CostBanner from "./CostBanner";
import HiwBanner from "./HiwBanner";
import ContactBanner from "./ContactBanner";
import HomeHero from "./HomeHero";
import ProjectHero from "./ProjectHero";
import ThreeDHero from "./ThreeDHero";
import CostHero from "./CostHero";
import HiwHero from "./HiwHero";
import ContactHero from "./ContactHero";
import AddPW from "./AddPW";
import ViewWalkthroughs from "./ViewWalkthroughs";
import EditPW from "./EditPW";
import DeletePW from "./DeletePW";
import { ActiveTermsPolicy } from "./ActiveTermsPolicy";
import { AddTermsPolicy } from "./AddTermsPolicy";
import { AddEmail } from "./AddEmail";
import { ActiveEmail } from "./ActiveEmail";
import { ActiveSocialMedia } from "./ActiveSocialMedia";
import { AddSocialMedia } from "./AddSocialMedia";
import AddMorePageLinks from "./AddMorePageLinks";
import ActiveMorePageLinks from "./ActiveMorePageLinks";
import AddSubService from "./AddSubService";
import ActiveSubService from "./ActiveSubService";
import JobApplications from "./JobApplications";
import AddJob from "./AddJob";
import ActiveJobListing from "./ActiveJobListing";

const AdminLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("dashboard");
  const [projects, setProjects] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const addProject = (newProject) => {
    setProjects((prevProjects) => [...prevProjects, newProject]);
  };

  const handleUpdateProject = (updatedProject) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === updatedProject.id ? updatedProject : project
      )
    );
  };

  const handleDeleteProject = (projectId) => {
    // Filter out the project to delete
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== projectId)
    );
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        setActiveSection={setActiveSection} // Pass function to set active section
      />

      {/* Content Area */}
      <div className="flex-1">
        <Header toggleSidebar={toggleSidebar} />
        <main className="p-6">
          {activeSection === "dashboard" && <Dashboard />}
          {activeSection === "serviceForm" && <ServiceForm />}
          {activeSection === "serviceManage" && <ServiceManage />}
          {activeSection === "addProject" && (
            <AddProjectForm addProject={addProject} />
          )}
          {activeSection === "editProject" && (
            <EditProjectForm
              projects={projects}
              onSubmit={handleUpdateProject}
            />
          )}
          {activeSection === "projectTable" && (
            <ProjectTable projects={projects} />
          )}

          {activeSection === "deleteProject" && (
            <DeleteProject projects={projects} onDelete={handleDeleteProject} />
          )}

          {activeSection === "houseCalculator" && (
            <HouseConstructorCalculator />
          )}
          {activeSection === "AddPackage" && <AddPackage />}
          {activeSection === "ActivePackages" && <ActivePackages />}
          {activeSection === "TalkToExpert" && <AdminTalkToExpert />}
          {activeSection === "ContactUsAdmin" && <ContactUsAdmin />}
          {activeSection === "ActiveUser" && <ActiveUser />}
          {activeSection === "AddUser" && <AddUser />}
          {activeSection === "ActiveBankingPartners" && (
            <ActiveBankingPartners />
          )}
          {activeSection === "AddBankingPartner" && <AddBankingPartner />}
          {activeSection === "AddPermission" && <AddPermission />}
          {activeSection === "addCustomerTestimonials" && <AddTestimonials />}
          {activeSection === "editCustomerTestimonials" && <EditTestimonials />}

          {activeSection === "deleteCustomerTestimonials" && (
            <DeleteTestimonials />
          )}
          {activeSection === "addFAQs" && <AddFAQForm />}
          {activeSection === "editFAQs" && <EditFAQForm />}
          {activeSection === "deleteFAQs" && <DeleteFAQForm />}
          {activeSection === "addNews" && <AddNews />}
          {activeSection === "deleteNews" && <DeleteNews />}
          {activeSection === "editNews" && <EditNews />}
          {activeSection === "homeBanner" && <HomeBanner />}
          {activeSection === "projectsBanner" && <ProjectsBanner />}
          {activeSection === "3dBanner" && <ThreeDBanner />}
          {activeSection === "costBanner" && <CostBanner />}
          {activeSection === "hiwBanner" && <HiwBanner />}
          {activeSection === "contactBanner" && <ContactBanner />}
          {activeSection === "homeHero" && <HomeHero />}
          {activeSection === "projectsHero" && <ProjectHero />}
          {activeSection === "3dHero" && <ThreeDHero />}
          {activeSection === "costHero" && <CostHero />}
          {activeSection === "hiwHero" && <HiwHero />}
          {activeSection === "contactHero" && <ContactHero />}
          {activeSection === "addPW" && <AddPW />}
          {activeSection === "editPW" && <EditPW />}
          {activeSection === "deletePW" && <DeletePW />}
          {activeSection === "activePW" && <ViewWalkthroughs />}
          {activeSection === "AddTermsPolicy" && <AddTermsPolicy />}
          {activeSection === "ActiveTermsPolicy" && <ActiveTermsPolicy />}
          {activeSection === "AddEmail" && <AddEmail />}
          {activeSection === "ActiveEmail" && <ActiveEmail />}
          {activeSection === "ActiveSocialMedia" && <ActiveSocialMedia />}
          {activeSection === "AddSocialMedia" && <AddSocialMedia />}
          {activeSection === "AddMorePageLinks" && <AddMorePageLinks />}
          {activeSection === "ActiveMorePageLinks" && <ActiveMorePageLinks />}
          {activeSection === "ActiveSubservices" && <ActiveSubService />}
          {activeSection === "AddSubservices" && <AddSubService />}
          {activeSection === "ActiveJobListing" && <ActiveJobListing />}
          {activeSection === "JobApplications" && <JobApplications />}
          {activeSection === "AddJob" && <AddJob />}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
