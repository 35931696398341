// src/components/ActiveJobListing.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveJobListing = () => {
  const [jobs, setJobs] = useState([]);
  const [editingJob, setEditingJob] = useState(null);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get("https://api.bhk99.com/jobs/all");
      setJobs(response.data);
    } catch (error) {
      toast.error("Error fetching jobs");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.bhk99.com/jobs/delete/${id}`);
      toast.success("Job deleted successfully");
      fetchJobs();
    } catch (error) {
      toast.error("Error deleting job");
    }
  };

  const handleEdit = async (id) => {
    const jobToEdit = jobs.find((job) => job._id === id);
    setEditingJob(jobToEdit);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.bhk99.com/jobs/edit/${editingJob._id}`, {
        title: editingJob.title,
        description: editingJob.description,
      });
      toast.success("Job updated successfully");
      setEditingJob(null);
      fetchJobs();
    } catch (error) {
      toast.error("Error updating job");
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-6">Active Job Listings</h2>
      <div className="space-y-4">
        {jobs.map((job) => (
          <div
            key={job._id}
            className="p-4 bg-white shadow rounded flex justify-between items-center"
          >
            <div>
              <h3 className="text-lg font-semibold">{job.title}</h3>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(job._id)}
                className="px-4 py-2 bg-[#FE6929] text-white font-semibold rounded hover:bg-orange-600"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(job._id)}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {editingJob && (
        <form
          onSubmit={handleUpdate}
          className="mt-6 bg-white p-4 shadow rounded space-y-4"
        >
          <div>
            <label>Job Title</label>
            <input
              type="text"
              value={editingJob.title}
              onChange={(e) =>
                setEditingJob({ ...editingJob, title: e.target.value })
              }
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div>
            <label>Job Description</label>
            <ReactQuill
              value={editingJob.description}
              onChange={(value) =>
                setEditingJob({ ...editingJob, description: value })
              }
              className="bg-white"
              required
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
          >
            Update Job
          </button>
        </form>
      )}
      <ToastContainer />
    </div>
  );
};

export default ActiveJobListing;
