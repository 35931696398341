import { useEffect, useState } from "react";

export const useJobDetailsData = (id) => {
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`https://api.bhk99.com/jobs/${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setJobDetails(data);
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };

    // Ensure that Jobid is valid and not undefined or null
    if (id) {
      fetchJobDetails();
    }
  }, [id]);

  return { jobDetails, loading };
};
