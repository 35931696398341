import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Navbar2.css"; // Import the custom CSS file
import { Link } from "react-router-dom";

export const Navbar2 = () => {
  return (
    <>
      <nav className="bg-white shadow dark:bg-gray-800 h-[100px]">
        <div className="container flex items-center justify-center p-6 mx-auto text-gray-600 capitalize dark:text-gray-300">
          {/* Logo - always visible */}
          <a href="javascript:void(0)">
            <img
              src="/images/logo1.png"
              alt="logo"
              className="lg:w-[105px] md:w-[80px] text-gray-800 dark:text-gray-200 font-poppins hover:text-[#FE6929]"
            />
          </a>

          {/* Nav links - hidden on screens of 400x850 */}
          <div className="hidden lg:flex lg:items-center lg:space-x-8 lg:ml-12 navbar-links">
            <Link
              to="/"
              className="text-gray-800 dark:text-gray-200 font-poppins hover:text-[#FE6929]"
            >
              Home
            </Link>

            <Link
              to="/3d-plans"
              className="text-gray-800 dark:text-gray-200 font-poppins hover:text-[#FE6929]"
            >
              3D Floor Plans
            </Link>

            <Link
              to="/OurProjects"
              className="border-b-2 border-transparent dark:hover:text-gray-200 font-poppins hover:text-[#FE6929]"
            >
              Our Projects
            </Link>

            <Link
              to="/calci"
              className="border-b-2 border-transparent font-poppins hover:text-[#FE6929] dark:hover:text-gray-200"
            >
              Cost Estimator
            </Link>

            <Link
              to="/HowItWorks"
              className="border-b-2 border-transparent font-poppins hover:text-[#FE6929] dark:hover:text-gray-200"
            >
              How it Works
            </Link>

            <Link
              to="/"
              className="inline-flex items-center px-3 py-2 text-white bg-[#FE6929] rounded-lg hover:bg-[#e45f23] transition-colors duration-300 ease-in-out shadow-lg"
            >
              Talk to Expert
              <i className="fas fa-arrow-right ml-3"></i>
            </Link>

            <Link
              to="/ContactUs"
              className="border-b-2 border-transparent hover:text-[#FE6929] dark:hover:text-gray-200"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};
