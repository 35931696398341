import { CiBank } from "react-icons/ci";
import { BsCoin } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { TbCurrencyRupee } from "react-icons/tb";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const EMICalciCompMobile = () => {
  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTenure, setLoanTenure] = useState("");
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);

  const navigate = useNavigate();

  const calculateEMI = () => {
    const P = parseFloat(loanAmount);
    const annualInterestRate = parseFloat(interestRate);
    const n = parseFloat(loanTenure) * 12; // Loan tenure in months
    const r = annualInterestRate / 12 / 100; // Monthly interest rate in decimal

    if (P && r && n) {
      const emiAmount = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
      const totalAmount = emiAmount * n;
      const totalInterestPayable = totalAmount - P;

      setEmi(emiAmount.toFixed(2));
      setTotalInterest(totalInterestPayable.toFixed(2));
      setTotalPayment(totalAmount.toFixed(2));
    } else {
      alert("Please enter valid input values.");
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-4 rounded-lg shadow-md bg-[#FFF5EE]  mb-8">
      <h2 className="text-xl font-semibold mb-6 font-poppins mt-[50px] md:text-left">
        EMI Estimator
      </h2>

      {/* Input Fields */}
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <CiBank className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">Loan Amount</label>
        </div>
        <input
          type="number"
          placeholder="Enter Loan Amount"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)}
        />

        <div className="flex items-center space-x-2">
          <BsCoin className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">Intrest Rate</label>
        </div>
        <input
          type="number"
          placeholder="Enter Intrest Rate"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
        />

        <div className="flex items-center space-x-2">
          <SlCalender className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">Loan Tenure</label>
        </div>
        <input
          type="number"
          placeholder="Enter Loan Tenure"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={loanTenure}
          onChange={(e) => setLoanTenure(e.target.value)}
        />
      </div>

      <button
        onClick={calculateEMI}
        className="w-full mt-6 px-4 py-2 text-white font-normal text-sm rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
      >
        Calculate Cost
      </button>

      {/* Result Section */}
      <div className="mt-8 p-4 bg-[#ffe2d6] w-full mx-auto rounded-3xl shadow-md">
        <h3 className="text-lg font-semibold mb-4 font-poppins text-center md:text-left">
          Estimated Cost
        </h3>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">Loan EMI:</span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700 text-xs">{emi}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">
              Total Intrest Payable:
            </span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700 text-xs">{totalInterest}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">Total Payment:</span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700 text-xs font-bold">
              {totalPayment}
            </span>
          </div>
        </div>
        <button
          onClick={() => navigate("/ContactUs")}
          className="w-full mt-4 px-4 py-2 text-white font-normal rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
        >
          Create Your Dream
        </button>
      </div>
    </div>
  );
};
