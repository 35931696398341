import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceForm = () => {
  const [name, setName] = useState("");

  const [image, setImage] = useState("");

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Optional: validate file type and size here
      setImage(file);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name);

      formData.append("image", image); // send the image file directly

      console.log("Form Data:", formData); // Log the form data for debugging

      const response = await axios.post(
        "https://api.bhk99.com/api/services",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
          },
        }
      );

      console.log("Service added successfully:", response.data); // Log success response
      toast.success("Service added successfully!");
      // Reset form fields after submission
      setName("");

      setImage(null); // Reset image to null after submission
    } catch (error) {
      console.error(
        "Error adding service:",
        error.response ? error.response.data : error.message
      );
      toast.error(
        "Error adding service: " +
          (error.response ? error.response.data.message : error.message)
      );
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleFormSubmit}
        className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full"
      >
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Add New Service
        </h2>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-semibold mb-2">
            Service Name
          </label>
          <input
            type="text"
            placeholder="Enter service name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-semibold mb-2">
            Service Image
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
        >
          Add Service
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ServiceForm;
