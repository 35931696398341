import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddMorePageLinks = () => {
  const [pageName, setPageName] = useState("");
  const [pageLink, setPageLink] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBackgroundImage(reader.result); // Base64 string
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const pageData = { pageName, pageLink, backgroundImage };

    try {
      const response = await fetch("https://api.bhk99.com/api/morepages", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pageData),
      });
      if (response.ok) {
        toast.success("Page added successfully!");
        setPageName("");
        setPageLink("");
        setBackgroundImage("");
      } else {
        toast.error("Failed to add the page.");
      }
    } catch (error) {
      toast.error("An error occurred while adding the page.");
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-xl font-bold mb-4">Add More Page Link</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Page Name</label>
          <input
            type="text"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            className="border w-full p-2 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Page Link</label>
          <input
            type="text"
            value={pageLink}
            onChange={(e) => setPageLink(e.target.value)}
            className="border w-full p-2 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Background Image</label>
          <input
            type="file"
            onChange={handleImageUpload}
            className="border w-full p-2 rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#FE6929] text-white font-semibold py-2 rounded-md hover:bg-[#d86025]"
        >
          Add Page
        </button>
      </form>
    </div>
  );
};

export default AddMorePageLinks;
