import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";

const AddSubService = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [subServiceName, setSubServiceName] = useState("");
  const [subServiceImage, setSubServiceImage] = useState("");
  const [subserviceInfo, setSubserviceInfo] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/api/services");
        setServices(response.data.services || []);
      } catch (error) {
        toast.error("Error fetching services");
      }
    };

    fetchServices();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSubServiceImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !selectedService ||
      !subServiceName ||
      !subServiceImage ||
      !subserviceInfo
    ) {
      toast.error("All fields are required");
      return;
    }

    try {
      await axios.post("https://api.bhk99.com/api/subservices", {
        serviceId: selectedService,
        subServiceName,
        subServiceImage,
        subserviceInfo,
      });
      toast.success("Subservice added successfully");
      setSelectedService("");
      setSubServiceName("");
      setSubServiceImage("");
      setSubserviceInfo("");
    } catch (error) {
      toast.error("Error : Kindly upload images below the size of 70 KB");
    }
  };

  return (
    <div className="p-6 max-w-xl mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add Subservice</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="service"
            className="block text-gray-700 font-medium mb-2"
          >
            Select a Service
          </label>
          <select
            id="service"
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
          >
            <option value="">Select a service</option>
            {services.map((service) => (
              <option key={service._id} value={service._id}>
                {service.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="subServiceName"
            className="block text-gray-700 font-medium mb-2"
          >
            Subservice Name
          </label>
          <input
            id="subServiceName"
            type="text"
            value={subServiceName}
            onChange={(e) => setSubServiceName(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="subServiceImage"
            className="block text-gray-700 font-medium mb-2"
          >
            Subservice Image
          </label>
          <input
            id="subServiceImage"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="subserviceInfo"
            className="block text-gray-700 font-medium mb-2"
          >
            Subservice Information
          </label>
          <ReactQuill
            value={subserviceInfo}
            onChange={setSubserviceInfo}
            className="bg-white"
          />
        </div>

        <button
          type="submit"
          className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
        >
          Add Subservice
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddSubService;
