import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useJobListingData } from "./Admin Panel/AddJobListingHome";
import { Footer } from "./Footer";
import { Navbar2 } from "./Navbar2";
import { NavbarTab } from "./NavbarTab";
import NavbarMobile from "./NavbarMobile";

const JobListing = () => {
  const navigate = useNavigate();
  const { jobs, loading } = useJobListingData();
  const [viewport, setViewport] = useState("large");

  const handleSeeDetails = (job) => {
    navigate(`/job-details/${job._id}`);
  };

  // Effect hook to handle viewport changes
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only once after the first render

  if (loading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen">
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <h1 className="text-4xl font-bold text-center mb-6 text-gray-800 lg:mt-12 md:mt-12">
        Explore Job Opportunities
      </h1>
      <div className="space-y-6 max-w-4xl mx-auto pb-12">
        {jobs.map((job) => (
          <div
            key={job._id}
            className="p-6 bg-white shadow-lg rounded-lg flex flex-col sm:flex-row justify-between items-center"
          >
            <div className="mb-4 sm:mb-0">
              <h2 className="text-2xl font-semibold text-gray-700">
                {job.title}
              </h2>
              <p className="text-sm text-gray-500">JOB ID: {job.jobId}</p>
            </div>
            <button
              onClick={() => handleSeeDetails(job)}
              className="px-4 py-2 bg-[#FE6929] text-white font-semibold rounded-lg hover:bg-orange-600"
            >
              See Details
            </button>
          </div>
        ))}
      </div>
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </div>
  );
};

export default JobListing;
