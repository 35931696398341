// src/components/AdminPanel/JobApplications.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify"; // Import Toastify

const JobApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all job applications from the database
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/jobapplication"
        );
        setApplications(response.data);
      } catch (error) {
        setError("Failed to load job applications.");
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  // Delete job application by ID with confirmation
  const handleRemove = async (id) => {
    const confirmRemove = window.confirm(
      "Are you sure you want to remove this application?"
    );
    if (confirmRemove) {
      try {
        await axios.delete(`https://api.bhk99.com/api/jobapplication/${id}`);
        setApplications(
          applications.filter((application) => application._id !== id)
        ); // Remove deleted app from the list
        toast.success("Job application removed successfully!"); // Show success notification
      } catch (error) {
        setError("Failed to remove job application.");
        toast.error("Error removing the job application."); // Show error notification
      }
    }
  };

  // If the data is still loading
  if (loading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  // If there's an error loading data
  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold text-center mb-6">Job Applications</h1>
      <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
        <table className="table-auto w-full text-sm text-left">
          <thead className="bg-gray-100">
            <tr>
              <th className="border px-4 py-2">Full Name</th>
              <th className="border px-4 py-2">Job Title</th>
              <th className="border px-4 py-2">Email</th>
              <th className="border px-4 py-2">Phone</th>
              <th className="border px-4 py-2">Resume</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((application) => (
              <tr key={application._id} className="hover:bg-gray-50">
                <td className="border px-4 py-2">{application.fullName}</td>
                <td className="border px-4 py-2">{application.jobTitle}</td>
                <td className="border px-4 py-2">{application.email}</td>
                <td className="border px-4 py-2">{application.phone}</td>
                <td className="border px-4 py-2">
                  <a
                    href={`https://api.bhk99.com${application.resumeUrl}`}
                    target="_blank" // Opens in a new tab
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    Download Resume
                  </a>
                </td>
                <td className="border px-4 py-2 text-center">
                  <button
                    onClick={() => handleRemove(application._id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobApplications;
