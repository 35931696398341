import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditProjectForm = ({ onSubmit }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    cityName: "",
    title: "",
    propertyType: "",
    plotDimensions: "",
    houseBudget: "",
    roadFacing: "",
    numberOfFloors: "",
    typeOfConstruction: "",
    numberOfBedrooms: "",
    vastuCompliant: false,
    projectDetails: "",
    threeDModuloLink: "",
    propertyImage: null,
    additionalImages: [],
    projectType: "",
  });

  const fetchProjects = async () => {
    try {
      const response = await fetch("https://api.bhk99.com/fetch/projects");
      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to load projects.");
    }
  };

  useEffect(() => {
    fetchProjects(); // Call fetchProjects when the component mounts
  }, []);

  const handleProjectSelect = (e) => {
    const projectId = e.target.value;
    const project = projects.find(
      (proj) => proj.autoIncrementId === parseInt(projectId)
    );
    setSelectedProject(project);
    if (project) {
      setFormData({
        cityName: project.cityName,
        title: project.title,
        propertyType: project.propertyType,
        plotDimensions: project.plotDimensions,
        houseBudget: project.houseBudget,
        roadFacing: project.roadFacing,
        numberOfFloors: project.numberOfFloors,
        typeOfConstruction: project.typeOfConstruction,
        numberOfBedrooms: project.numberOfBedrooms,
        vastuCompliant: project.vastuCompliant,
        projectDetails: project.projectDetails,
        threeDModuloLink: project.threeDModuloLink, // Populate 3D Modulo link
        propertyImage: null, // Reset property image
        additionalImages: [], // Reset additional images
        projectType: project.projectType,
      });
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setFormData({
      cityName: "",
      title: "",
      propertyType: "",
      plotDimensions: "",
      houseBudget: "",
      roadFacing: "",
      numberOfFloors: "",
      typeOfConstruction: "",
      numberOfBedrooms: "",
      vastuCompliant: false,
      projectDetails: "",
      threeDModuloLink: "",
      propertyImage: null,
      additionalImages: [],
      projectType: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "propertyImage") {
      setFormData((prevData) => ({
        ...prevData,
        propertyImage: files[0],
      }));
    } else if (name === "additionalImages") {
      setFormData((prevData) => ({
        ...prevData,
        additionalImages: Array.from(files),
      }));
    }
  };

  const handleQuillChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      projectDetails: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((file) => formDataToSend.append(key, file));
        } else {
          formDataToSend.append(key, value);
        }
      });

      const response = await fetch(
        `https://api.bhk99.com/update/updateProject/${selectedProject.autoIncrementId}`,
        {
          method: "PUT",
          body: formDataToSend,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update project");
      }

      const updatedProject = await response.json();
      toast.success(updatedProject.message);
      resetForm(); // Reset form after successful update
      fetchProjects(); // Re-fetch projects to refresh the list
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Failed to update project.");
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Edit Project</h2>
      <div className="mb-4">
        <label
          htmlFor="projectSelect"
          className="block text-sm font-medium text-gray-700"
        >
          Select Project
        </label>
        <select
          id="projectSelect"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          onChange={handleProjectSelect}
        >
          <option value="">--Select a project--</option>
          {projects.map((project) => (
            <option
              key={project.autoIncrementId}
              value={project.autoIncrementId}
            >
              {project.title}
            </option>
          ))}
        </select>
      </div>
      {selectedProject && (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              City Name
            </label>
            <input
              type="text"
              name="cityName"
              value={formData.cityName}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Property Type
            </label>
            <select
              name="propertyType"
              value={formData.propertyType}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">Select property type</option>
              <option value="Plot">Plot</option>
              <option value="Studio">Studio</option>
              <option value="Duplex">Duplex</option>
              <option value="Pent House">Pent House</option>
              <option value="Villa">Villa</option>
              <option value="Apartments">Apartments</option>
              <option value="Independent House">Independent House</option>
              <option value="Builder Floor">Builder Floor</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Project Type
            </label>
            <select
              name="projectType"
              value={formData.projectType}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">--Select Project Type--</option>
              <option value="Residential">Residential</option>
              <option value="Commercial">Commercial</option>
              <option value="Construction">Construction</option>
              <option value="Mixed-Use">Mixed-Use</option>
              <option value="Design & Planning">Design & Planning</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Plot Dimensions
            </label>
            <select
              name="plotDimensions"
              value={formData.plotDimensions}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">--Select Plot Dimensions--</option>
              <option value="20x20 sqft">20x20 sqft</option>
              <option value="20x30 sqft">20x30 sqft</option>
              <option value="20x40 sqft">20x40 sqft</option>
              <option value="20x50 sqft">20x50 sqft</option>
              <option value="30x20 sqft">30x20 sqft</option>
              <option value="30x30 sqft">30x30 sqft</option>
              <option value="30x40 sqft">30x40 sqft</option>
              <option value="30x50 sqft">30x50 sqft</option>
              <option value="40x20 sqft">40x20 sqft</option>
              <option value="40x30 sqft">40x30 sqft</option>
              <option value="40x40 sqft">40x40 sqft</option>
              <option value="40x50 sqft">40x50 sqft</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              House Budget
            </label>
            <input
              type="text"
              name="houseBudget"
              value={formData.houseBudget}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Road Facing
            </label>
            <select
              name="roadFacing"
              value={formData.roadFacing}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">--Select Road Facing--</option>
              <option value="north">North</option>
              <option value="south">South</option>
              <option value="east">East</option>
              <option value="west">West</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Number of Floors
            </label>
            <input
              type="text"
              name="numberOfFloors"
              value={formData.numberOfFloors}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Type of Construction
            </label>
            <select
              name="typeOfConstruction"
              value={formData.typeOfConstruction}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">--Select Type of Construction--</option>
              <option value="Brick">Brick</option>
              <option value="Concrete">Concrete</option>
              <option value="Wood">Wood</option>
              <option value="Steel">Steel</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Number of Bedrooms
            </label>
            <select
              name="numberOfBedrooms"
              value={formData.numberOfBedrooms}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">--Select Number of Bedrooms--</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4+">4+</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="vastuCompliant"
                checked={formData.vastuCompliant}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <span className="ml-2 block text-sm text-gray-700">
                Vastu Compliant
              </span>
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="addOnHome"
              id="addOnHome"
              checked={formData.addOnHome}
              onChange={handleChange}
              className="h-5 w-5 text-[#FE6929] border-gray-300 rounded focus:ring-[#FE6929]"
            />
            <label
              htmlFor="addOnHome"
              className="ml-2 text-sm font-medium text-gray-700"
            >
              Add-On Home
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Project Details
            </label>
            <ReactQuill
              value={formData.projectDetails}
              onChange={handleQuillChange}
              className="mt-1 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              3D Modulo Link
            </label>
            <input
              type="url"
              name="threeDModuloLink"
              value={formData.threeDModuloLink}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Property Image
            </label>
            <input
              type="file"
              name="propertyImage"
              accept="image/*"
              onChange={handleFileChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Additional Images
            </label>
            <input
              type="file"
              name="additionalImages"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div className="mb-4">
            <button
              type="submit"
              className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929]"
            >
              Update Project
            </button>
          </div>
          <ToastContainer />
        </form>
      )}
    </div>
  );
};

export default EditProjectForm;
