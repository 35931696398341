// src/components/AdminLayout.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboard from "./Dashboard";
import ServiceForm from "./ServiceForm";
import ServiceManage from "./ServiceManage";
import AddProjectForm from "./AddProjectForm";
import EditProjectForm from "./EditProjectForm";
import ProjectTable from "./ProjectTable";
import DeleteProject from "./DeleteProject"; // Import the DeleteProject component
import HouseConstructorCalculator from "./HouseConstructorCalculator";
import ActivePackages from "./ActivePackages";
import AddPackage from "./AddPackage";
import ContactUsAdmin from "./ContactUsAdmin";
import AdminTalkToExpert from "./AdminTalkToExpert";
import AddUser from "./AddUser";
import ActiveUser from "./ActiveUser";
import ActiveBankingPartners from "./ActiveBankingPartners";
import AddBankingPartner from "./AddBankingPartner";
import AddPermission from "./AddPermission";
import AddTestimonials from "./AddTestimonials";
import EditTestimonials from "./EditTestimonials";
import DeleteTestimonials from "./DeleteTestimonials";
import AddFAQForm from "./AddFAQForm";
import EditFAQForm from "./EditFAQForm";
import DeleteFAQForm from "./DeleteFAQForm";
import AddNews from "./AddNews";
import DeleteNews from "./DeleteNews";
import EditNews from "./EditNews";
import UserSideBar from "./UserSideBar";
import UserHeader from "./UserHeader";
import UserDetails from "./UserDetails";

const UserLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("dashboard");
  const [projects, setProjects] = useState([]);
  const [userPermissions, setUserPermissions] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    // Retrieve email from localStorage

    const email = localStorage.getItem("userEmail");
    console.log(email);

    // Fetch user data using the email
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.bhk99.com/api/users/users/${email}`
        );
        console.log(response.data);
        setUserPermissions(response.data.permissions);
        console.log(response.data.permissions);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch user data.");
      }
    };

    if (email) {
      fetchUserData();
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const addProject = (newProject) => {
    setProjects((prevProjects) => [...prevProjects, newProject]);
  };

  const handleUpdateProject = (updatedProject) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === updatedProject.id ? updatedProject : project
      )
    );
  };

  const handleDeleteProject = (projectId) => {
    // Filter out the project to delete
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== projectId)
    );
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <UserSideBar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        setActiveSection={setActiveSection} // Pass function to set active section
        userPermissions={userPermissions}
      />

      {/* Content Area */}
      <div className="flex-1">
        <UserHeader
          toggleSidebar={toggleSidebar}
          setActiveSection={setActiveSection}
        />
        <main className="p-6">
          {activeSection === "dashboard" && <Dashboard />}
          {activeSection === "serviceForm" && <ServiceForm />}
          {activeSection === "serviceManage" && <ServiceManage />}
          {activeSection === "addProject" && (
            <AddProjectForm addProject={addProject} />
          )}
          {activeSection === "editProject" && (
            <EditProjectForm
              projects={projects}
              onSubmit={handleUpdateProject}
            />
          )}
          {activeSection === "projectTable" && (
            <ProjectTable projects={projects} />
          )}

          {activeSection === "deleteProject" && (
            <DeleteProject projects={projects} onDelete={handleDeleteProject} />
          )}

          {activeSection === "houseCalculator" && (
            <HouseConstructorCalculator />
          )}
          {activeSection === "AddPackage" && <AddPackage />}
          {activeSection === "ActivePackages" && <ActivePackages />}
          {activeSection === "TalkToExpert" && <AdminTalkToExpert />}
          {activeSection === "ContactUsAdmin" && <ContactUsAdmin />}
          {activeSection === "ActiveUser" && <ActiveUser />}
          {activeSection === "AddUser" && <AddUser />}
          {activeSection === "ActiveBankingPartners" && (
            <ActiveBankingPartners />
          )}
          {activeSection === "AddBankingPartner" && <AddBankingPartner />}
          {activeSection === "AddPermission" && <AddPermission />}
          {activeSection === "addCustomerTestimonials" && <AddTestimonials />}
          {activeSection === "editCustomerTestimonials" && <EditTestimonials />}

          {activeSection === "deleteCustomerTestimonials" && (
            <DeleteTestimonials />
          )}
          {activeSection === "addFAQs" && <AddFAQForm />}
          {activeSection === "editFAQs" && <EditFAQForm />}
          {activeSection === "deleteFAQs" && <DeleteFAQForm />}
          {activeSection === "addNews" && <AddNews />}
          {activeSection === "deleteNews" && <DeleteNews />}
          {activeSection === "editNews" && <EditNews />}
          {activeSection === "userDetails" && <UserDetails />}
        </main>
      </div>
    </div>
  );
};

export default UserLayout;
