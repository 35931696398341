import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveMorePageLinks = () => {
  const [pages, setPages] = useState([]);
  const [editPage, setEditPage] = useState(null);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const response = await fetch("https://api.bhk99.com/api/morepages");
      const data = await response.json();
      setPages(data);
    } catch (error) {
      toast.error("Failed to fetch pages.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://api.bhk99.com/api/morepages/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        toast.success("Page deleted successfully!");
        fetchPages();
      } else {
        toast.error("Failed to delete the page.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the page.");
    }
  };

  const handleEdit = (page) => {
    setEditPage(page);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setEditPage({ ...editPage, backgroundImage: reader.result }); // Base64 string
    };
    reader.readAsDataURL(file);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { _id, pageName, pageLink, backgroundImage } = editPage;

    try {
      const response = await fetch(
        `https://api.bhk99.com/api/morepages/${_id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ pageName, pageLink, backgroundImage }),
        }
      );
      if (response.ok) {
        toast.success("Page updated successfully!");
        fetchPages();
        setEditPage(null); // Clear edit form after submission
      } else {
        toast.error("Failed to update the page.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the page.");
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-xl font-bold mb-4">Active More Pages</h1>
      {editPage ? (
        <form onSubmit={handleUpdate}>
          <div className="mb-4">
            <label className="block text-gray-700">Page Name</label>
            <input
              type="text"
              value={editPage.pageName}
              onChange={(e) =>
                setEditPage({ ...editPage, pageName: e.target.value })
              }
              className="border w-full p-2 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Page Link</label>
            <input
              type="text"
              value={editPage.pageLink}
              onChange={(e) =>
                setEditPage({ ...editPage, pageLink: e.target.value })
              }
              className="border w-full p-2 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Background Image</label>
            <input
              type="file"
              onChange={handleImageUpload}
              className="border w-full p-2 rounded"
            />
            {editPage.backgroundImage && (
              <img
                src={editPage.backgroundImage}
                alt="Preview"
                className="mt-2 h-20 w-20 object-cover"
              />
            )}
          </div>
          <button
            type="submit"
            className="bg-[#FE6929] text-white px-4 py-2 rounded hover:bg-[#fe4229]"
          >
            Update Page
          </button>
        </form>
      ) : (
        <ul>
          {pages.map((page) => (
            <li
              key={page._id}
              className="flex items-center justify-between mb-4"
            >
              <span>{page.pageName}</span>
              <div>
                <button
                  onClick={() => handleEdit(page)}
                  className="bg-[#FE6929] text-white px-2 py-1 rounded mr-2 hover:bg-[#fe4229]"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(page._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ActiveMorePageLinks;
