import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HouseConstructorCalculator = () => {
  const [superBuiltupCost, setSuperBuiltupCost] = useState("");
  const [balconyCost, setBalconyCost] = useState("");
  const [parkingCost, setParkingCost] = useState("");
  const [boundaryCost, setBoundaryCost] = useState("");
  const [city, setCity] = useState(""); // New state for city

  const handleConfirmChanges = async () => {
    const data = {
      superBuiltupCost,
      balconyCost,
      parkingCost,
      boundaryCost,
      city, // Include city in the payload
    };

    try {
      const response = await axios.post(
        "https://api.bhk99.com/houseCalci/house-calci",
        data
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Changes confirmed and stored in database:", response.data);
        toast.success("Changes confirmed successfully!");
      } else {
        console.error("Failed to store data", response.status, response.data);
        toast.error("Failed to store data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-md p-8 max-w-lg w-full">
        <h2 className="text-2xl font-bold text-center mb-6">
          House Construction Calculator
        </h2>

        {/* Input Fields */}
        <div className="space-y-4">
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Super Built-up Cost Variable
            </label>
            <input
              type="number"
              value={superBuiltupCost}
              onChange={(e) => setSuperBuiltupCost(e.target.value)}
              placeholder="Enter Super Built-up Cost Variable"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Balcony Cost Variable
            </label>
            <input
              type="number"
              value={balconyCost}
              onChange={(e) => setBalconyCost(e.target.value)}
              placeholder="Enter Balcony Cost Variable"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Parking Cost Variable
            </label>
            <input
              type="number"
              value={parkingCost}
              onChange={(e) => setParkingCost(e.target.value)}
              placeholder="Enter Parking Cost Variable"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Boundary Cost Variable
            </label>
            <input
              type="number"
              value={boundaryCost}
              onChange={(e) => setBoundaryCost(e.target.value)}
              placeholder="Enter Boundary Cost Variable"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-2">City</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter City"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
        </div>

        {/* Confirm Button */}
        <div className="mt-6">
          <button
            onClick={handleConfirmChanges}
            className="w-full bg-[#FE6929] text-white font-bold py-3 rounded-lg transition duration-200 border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] font-poppins"
          >
            Confirm Changes
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default HouseConstructorCalculator;
