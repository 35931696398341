import { useState } from "react";
import { TbCurrencyRupee } from "react-icons/tb";
import { CiBank } from "react-icons/ci";
import { BsCoin } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

export const EMICalciComp = () => {
  const navigate = useNavigate();

  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTenure, setLoanTenure] = useState("");
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);

  const calculateEMI = () => {
    const P = parseFloat(loanAmount);
    const annualInterestRate = parseFloat(interestRate);
    const n = parseFloat(loanTenure) * 12; // Loan tenure in months
    const r = annualInterestRate / 12 / 100; // Monthly interest rate in decimal

    if (P && r && n) {
      const emiAmount = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
      const totalAmount = emiAmount * n;
      const totalInterestPayable = totalAmount - P;

      setEmi(emiAmount.toFixed(2));
      setTotalInterest(totalInterestPayable.toFixed(2));
      setTotalPayment(totalAmount.toFixed(2));
    } else {
      alert("Please enter valid input values.");
    }
  };

  return (
    <div className="lg:w-[800px] lg:h-[700px] mx-auto p-6 rounded-lg shadow-md lg:mt-[100px] lg:mb-[40px] bg-[#FFF5EE]">
      <h2 className="text-2xl font-semibold mb-6 font-poppins lg:mb-[40px]">
        EMI Estimator
      </h2>

      {/* Input Fields */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <CiBank className=" text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">Loan Amount</label>
        </div>
        <input
          type="number"
          placeholder="Enter Loan Amount"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <BsCoin className=" text-[#FE6929]" />
          <label className="block text-gray-700 font-poppins">
            Interest Rate
          </label>
        </div>
        <input
          type="number"
          placeholder="Enter Interest Rate"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <SlCalender className=" text-[#FE6929]" />
          <label className="block text-gray-700 font-poppins">
            Loan Tenure (Years)
          </label>
        </div>
        <input
          type="number"
          placeholder="Enter Loan Tenure"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={loanTenure}
          onChange={(e) => setLoanTenure(e.target.value)}
        />
      </div>

      <button
        onClick={calculateEMI}
        className="lg:w-[150px] font-poppins mt-6 px-4 py-2 text-white font-normal lg:text-sm rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
      >
        Calculate EMI
      </button>

      {/* Result Section - Always visible */}
      <div className="mt-8 p-4 bg-[#ffe2d6] rounded-3xl shadow-md lg:w-[350px] mx-auto">
        <h3 className="text-xl font-semibold mb-4 font-poppins">
          Estimated Cost
        </h3>
        <div className="mb-2">
          <div className="flex items-center space-x-2 lg:ml-2">
            <span className="font-poppins font-semibold">
              Loan EMI (Monthly):
            </span>
            <span className="text-gray-700">
              <TbCurrencyRupee className=" text-[#FE6929]" />
            </span>
            <span className="text-gray-700">{emi}</span>
          </div>
        </div>
        <div className="mb-2">
          <div className="flex items-center space-x-2 lg:ml-2">
            <span className="font-poppins font-semibold">
              Total Interest Payable:
            </span>
            <span className="text-gray-700">
              <TbCurrencyRupee className=" text-[#FE6929]" />
            </span>
            <span className="text-gray-700">{totalInterest}</span>
          </div>
        </div>
        <div className="mb-2">
          <div className="flex items-center space-x-2 lg:ml-2">
            <span className="font-poppins font-semibold">Total Payment:</span>{" "}
            <span className="text-gray-700">
              <TbCurrencyRupee className=" text-[#FE6929]" />
            </span>
            <span className="text-gray-700 font-bold">{totalPayment}</span>
          </div>
        </div>

        <button
          onClick={() => navigate("/ContactUs")}
          className="lg:w-[240px] font-poppins px-4 py-2 text-white font-normal rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
        >
          Create Your Dream
        </button>
      </div>
    </div>
  );
};
