import React from "react";

export const InteriorHome = ({ project }) => {
  // Ensure the project object exists and has additionalImages property
  const images = project?.additionalImages || [];
  const projectdata = project?.projectDetails;

  console.log(projectdata);
  console.log(images);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-white lg:mt-[30px] md:mt-[-320px] mt-[20px]">
      {/* Image Grid */}
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 p-4">
        {images.length > 0 ? (
          images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Image ${index + 1}`}
              className="lg:w-[240px] md:w-[150px] w-[100px] lg:h-[240px] md:h-[150px] h-[100px] object-cover lg:ml-4 md:ml-4"
            />
          ))
        ) : (
          <p className="text-center col-span-3">No images available</p>
        )}
      </div>

      {/* Project Details */}
      <div className="w-full max-w-4xl p-4 mt-4 mb-[-20px] text-left">
        <div
          className="text-gray-500 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: projectdata }} // Render HTML content
        />
      </div>

      {/* Add custom styles for lists */}
      <style>
        {`
          ul {
            list-style-type: disc; /* Ensure bullets are shown */
            margin-left: 1.5rem;   /* Indent list items */
          }
          ol {
            list-style-type: decimal; /* Numbered lists */
            margin-left: 1.5rem;      /* Indent list items */
          }
          li {
            margin-bottom: 0.5rem; /* Add spacing between items */
          }
        `}
      </style>
    </div>
  );
};
