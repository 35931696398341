// src/components/Header.js
import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai"; // Hamburger icon

const Header = ({ toggleSidebar }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user session data from localStorage
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    // Redirect to AdminLogin page
    navigate("/door-open");
  };

  return (
    <header className="flex justify-between items-center p-4 bg-white shadow-md md:pl-72">
      {/* Hamburger Button for Mobile */}
      <button className="md:hidden text-2xl" onClick={toggleSidebar}>
        <AiOutlineMenu />
      </button>

      <div className="relative flex items-center ml-auto">
        {/* Profile Photo and Name */}
        <FaUserCircle className="text-3xl" />
        <button
          className="flex items-center space-x-2 ml-2"
          onClick={() => setIsProfileOpen(!isProfileOpen)}
        >
          <span className="text-lg">Admin</span>
          <MdOutlineKeyboardArrowDown className="text-xl" />
        </button>

        {/* Dropdown */}
        {isProfileOpen && (
          <ul
            className="absolute top-full right-0 mt-2 w-48 bg-white text-gray-800 shadow-lg rounded-md z-10"
            style={{ transformOrigin: "top right" }}
          >
            <li
              className="p-3 hover:bg-gray-100 cursor-pointer"
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        )}
      </div>
    </header>
  );
};

export default Header;
