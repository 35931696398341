import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, role }) => {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");

  if (!token || userRole !== role) {
    // If no token or role mismatch, redirect to login
    return <Navigate to="/door-open" replace />;
  }

  return children;
};

export default ProtectedRoute;
