import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Customers = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const testimonialsPerPage = 6;

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchTestimonials/get-testimonials"
        );
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  // Calculate the current testimonials to display based on the current page
  const indexOfLastTestimonial = currentPage * testimonialsPerPage;
  const indexOfFirstTestimonial = indexOfLastTestimonial - testimonialsPerPage;
  const currentTestimonials = testimonials.slice(
    indexOfFirstTestimonial,
    indexOfLastTestimonial
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(testimonials.length / testimonialsPerPage);

  // Function to handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4 lg:ml-12 sm:ml-1">
        <div className="text-center ">
          <h2 className="text-4xl font-semibold font-poppins mb-4 text-center ">
            Our Customers
          </h2>
          <span className="text-gray-400 text-sm font-poppins mb-24 lg:block text-center ">
            Our 800 happy family members are part of BHK99 family.
          </span>
        </div>

        {/* Testimonials grid */}
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-4 mt-6 lg:ml-[30px]">
          {currentTestimonials.map((testimonial) => (
            <div
              key={testimonial._id}
              className="bg-white rounded-none shadow-none overflow-hidden flex flex-col max-w-xs mx-auto"
            >
              <div className="flex-grow">
                <iframe
                  className="w-full h-32 sm:h-40 lg:h-48"
                  src={testimonial.videoLink}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="p-4 bg-transparent">
                <span className="text-black font-poppins font-normal text-sm">
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    className="text-[#FE6929]"
                  />{" "}
                  <p
                    className="text-black font-poppins font-normal text-sm"
                    dangerouslySetInnerHTML={{ __html: testimonial.details }}
                  />
                  <p className="text-black font-poppins font-semibold text-sm mt-2">
                    {testimonial.customerName}
                  </p>
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination controls */}
        <div className="mt-6 flex justify-center space-x-2">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === index + 1
                  ? "bg-[#FE6929] text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Customers;
