import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Profit = () => {
  const [homeBanner3Image, setHomeBanner3Image] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBannerImage = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/banner/getBanner"
        );
        if (response.status === 200) {
          setHomeBanner3Image(
            `data:image/jpeg;base64,${response.data.homeBanner3}`
          );
        }
      } catch (error) {
        console.error("Error fetching banner image:", error);
      }
    };

    fetchBannerImage();
  }, []);

  return (
    <section className="py-6 bg-white ">
      <div className="container mx-auto px-4 md:px-8 lg:px-16 flex flex-col lg:flex-row items-center mt-16 md:mt-32 lg:mt-48">
        {/* Left Side Content */}
        <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold font-poppins mb-6 md:mb-12 lg:mb-7">
            Profit Sharing Program.
          </h2>
          <span className="text-gray-400 text-sm md:text-base lg:text-sm font-poppins">
            Refer your family & friends looking for house construction
            <br />
            <p className="text-gray-400 text-xs md:text-sm font-poppins mb-8 lg:mb-12">
              and earn cashbacks/discounts* up to 25,000 INR today.
            </p>
          </span>

          {/* Button Section */}
          <div className="flex justify-center lg:justify-start">
            <button
              onClick={() => navigate("/ContactUs")}
              className="bg-[#FE6929] text-white border-2 border-[#FE6929] py-2 px-6 rounded-full hover:bg-white hover:text-[#FE6929] transition-colors mt-2"
            >
              Learn More <FontAwesomeIcon icon={faArrowRightLong} />
            </button>
          </div>
        </div>

        {/* Right Side Image */}
        <div className="lg:w-1/2 flex justify-center lg:justify-end">
          <img
            src={homeBanner3Image} // Replace this URL with your actual image source
            alt="Profit Sharing"
            className="w-full max-w-xs md:max-w-md h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Profit;
