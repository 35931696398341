import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import fetchMorePageLinks from "./Admin Panel/FetchMorePageLinks";
import NavbarMobile from "./NavbarMobile";

const MorePage = () => {
  const [pages, setPages] = useState([]);
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    // Fetch pages from the backend
    const fetchData = async () => {
      const data = await fetchMorePageLinks();
      setPages(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col">
      {/* Page Content */}
      <div className="flex-grow overflow-y-scroll">
        {pages.map((page, index) => (
          <div
            key={index}
            className="relative w-full h-[33.33vh] flex items-center justify-center mb-1"
            style={{
              backgroundImage: `url(${page.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* Page Name with Link */}
            <a
              href={page.pageLink}
              className="bg-black bg-opacity-50 text-white py-2 px-6 rounded-md text-lg hover:bg-opacity-70"
              target="_blank"
              rel="noopener noreferrer"
            >
              {page.pageName}
            </a>
          </div>
        ))}
      </div>

      {/* NavbarMobile positioned at the bottom */}
      {viewport === "mobile" && (
        <div className="absolute bottom-0 w-full">
          <NavbarMobile />
        </div>
      )}
    </div>
  );
};

export default MorePage;
