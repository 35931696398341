export const WhyUs = () => {
  const services = [
    {
      image: "images/why1.png",
      title: "Safe money transaction",
    },
    {
      image: "images/why2.png",
      title: "Absolute Transparency",
    },
    {
      image: "images/why35.png",
      title: "Quality Control",
    },
    {
      image: "images/why34.png",
      title: "Zero Delays",
    },
  ];

  return (
    <section className="py-8 bg-[#FFFFFF]">
      <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-2">
        <div className="max-w-screen-lg mx-auto">
          <h2 className="text-3xl md:text-4xl font-semibold font-poppins mb-8 text-center lg:text-center">
            Why BHK99?
          </h2>
          <span className="text-gray-400 text-sm font-poppins mb-12 block text-center lg:text-center">
            With our personalized approach and extensive listing, we'll guide
            you to your ideal abode, where
            <br className="hidden md:inline" />
            comfort and happiness await.
          </span>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-16">
          {services.map((service, index) => (
            <div
              key={index}
              className="w-full max-w-xs mx-auto lg:h-80 p-6 rounded-3xl shadow-lg transition-transform transform bg-[#ffe4e1] hover:scale-105"
            >
              <div className="flex justify-center items-center md:w-24 md:h-24 lg:w-40 lg:h-40 sm:w-10 sm:h-10 overflow-hidden rounded-full mx-auto mb-4">
                <img
                  src={service.image}
                  className="w-full h-full object-cover"
                  alt={service.title}
                />
              </div>

              <div className="text-center">
                <h3 className="lg:text-lg md:text-sm font-semibold  mb-2 font-poppins text-gray-600">
                  {service.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
