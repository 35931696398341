import { FaLocationDot } from "react-icons/fa6";
import { PiRuler } from "react-icons/pi";
import { IoUmbrellaOutline } from "react-icons/io5";
import { FaCar } from "react-icons/fa";
import { PiWall } from "react-icons/pi";
import { TbCurrencyRupee } from "react-icons/tb";
import { useState, useEffect } from "react";
import axios from "axios";

export const HouseCalciCompMobile = () => {
  const [pincode, setPincode] = useState("");
  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [balconyArea, setBalconyArea] = useState("");
  const [carParkingArea, setCarParkingArea] = useState("");
  const [boundaryArea, setBoundaryArea] = useState("");

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [result, setResult] = useState({
    superBuiltupCost: 0,
    balconyCost: 0,
    parkingCost: 0,
    boundaryCost: 0,
    totalCost: 0,
  });

  const [costFactors, setCostFactors] = useState({
    superBuiltup: 1600,
    balcony: 1325,
    parking: 1340,
    boundary: 1800,
  });

  // Fetch list of cities for the dropdown
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/houseCalci/get-cities"
        );
        setCities(response.data.data);
      } catch (error) {
        console.error("Error fetching cities", error);
      }
    };
    fetchCities();
  }, []);

  // Fetch cost factors for the selected city
  useEffect(() => {
    if (selectedCity) {
      const fetchCostFactorsForCity = async () => {
        try {
          const response = await axios.get(
            `https://api.bhk99.com/houseCalci/get-house-calci/${selectedCity}`
          );
          const { superBuiltupCost, balconyCost, parkingCost, boundaryCost } =
            response.data.data;
          setCostFactors({
            superBuiltup: superBuiltupCost,
            balcony: balconyCost,
            parking: parkingCost,
            boundary: boundaryCost,
          });
        } catch (error) {
          console.error("Error fetching cost factors for the city", error);
        }
      };
      fetchCostFactorsForCity();
    }
  }, [selectedCity]);

  const calculateCost = () => {
    const superBuiltupCost = superBuiltupArea * costFactors.superBuiltup;
    const balconyCost = balconyArea * costFactors.balcony;
    const parkingCost = carParkingArea * costFactors.parking;
    const boundaryCost = boundaryArea * costFactors.boundary;
    const totalCost =
      superBuiltupCost + balconyCost + parkingCost + boundaryCost;

    setResult({
      superBuiltupCost,
      balconyCost,
      parkingCost,
      boundaryCost,
      totalCost,
    });
  };

  return (
    <div className="w-full max-w-md mx-auto p-4 rounded-lg shadow-md bg-[#FFF5EE] mb-8">
      <h2 className="text-xl font-semibold mb-6 font-poppins mt-[50px] md:text-left">
        Cost Estimator
      </h2>

      {/* City Dropdown */}
      <div className="mb-4">
        <div className="flex items-center space-x-2">
          <FaLocationDot className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">City</label>
        </div>
        <select
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a city</option>
          {cities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>

      {/* Input Fields */}
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <FaLocationDot className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">Pincode</label>
        </div>
        <input
          type="text"
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          placeholder="Enter Pincode"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex items-center space-x-2">
          <PiRuler className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">
            Super Built-up Area
          </label>
        </div>
        <input
          type="number"
          value={superBuiltupArea}
          onChange={(e) => setSuperBuiltupArea(e.target.value)}
          placeholder="Enter Super Built-up Area"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex items-center space-x-2">
          <IoUmbrellaOutline className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">
            Balcony & Utility Area
          </label>
        </div>
        <input
          type="number"
          value={balconyArea}
          onChange={(e) => setBalconyArea(e.target.value)}
          placeholder="Enter Balcony & Utility Area"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex items-center space-x-2">
          <FaCar className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">Car Parking Area</label>
        </div>
        <input
          type="number"
          value={carParkingArea}
          onChange={(e) => setCarParkingArea(e.target.value)}
          placeholder="Enter Car Parking Area"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <div className="flex items-center space-x-2">
          <PiWall className="text-[#FE6929] text-lg" />
          <label className="text-gray-700 font-poppins">Boundary Wall</label>
        </div>
        <input
          type="number"
          value={boundaryArea}
          onChange={(e) => setBoundaryArea(e.target.value)}
          placeholder="Enter Boundary Wall Area"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <button
        onClick={calculateCost}
        className="w-full mt-6 px-4 py-2 text-white font-normal text-sm rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
      >
        Calculate Cost
      </button>

      {/* Result Section */}
      <div className="mt-8 p-4 bg-[#ffe2d6] w-full mx-auto rounded-3xl shadow-md">
        <h3 className="text-lg font-semibold mb-4 font-poppins text-center md:text-left">
          Estimated Cost
        </h3>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">Per sq.ft:</span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700">{costFactors.superBuiltup}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">
              Super Built-up Cost:
            </span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700">{result.superBuiltupCost}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">Balcony Cost:</span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700">{result.balconyCost}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">Parking Cost:</span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700">{result.parkingCost}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">
              Boundary Wall Cost:
            </span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700">{result.boundaryCost}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-poppins font-semibold">Total Cost:</span>
            <TbCurrencyRupee className="text-[#FE6929]" />
            <span className="text-gray-700">{result.totalCost}</span>
          </div>
        </div>
        <button className="w-full mt-4 px-4 py-2 text-white font-normal rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out">
          Create Your Dream
        </button>
      </div>
    </div>
  );
};
