import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleGenerateOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.bhk99.com/generateOTP/generateOtp",
        { email, password }
      );
      if (response.status === 200) {
        setOtpSent(true);
        setError("");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to generate OTP.");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://api.bhk99.com/admin/login", {
        email,
        password,
        otp,
      });

      if (response.status === 200) {
        console.log(response.data);
        const { role, token } = response.data;

        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        localStorage.setItem("userEmail", email);

        // Navigate based on role
        if (role === "admin") {
          navigate("/admin-layout");
        } else if (role === "user") {
          navigate("/user-layout");
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || "Invalid credentials or OTP.");
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-3xl font-semibold text-center text-gray-900 mb-6">
          Admin/User Login
        </h2>

        {error && <p className="text-red-600 text-center mb-4">{error}</p>}

        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {otpSent && (
            <div>
              <label
                htmlFor="otp"
                className="block text-sm font-medium text-gray-700"
              >
                Enter OTP
              </label>
              <input
                type="text"
                id="otp"
                className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
          )}

          {!otpSent && (
            <button
              type="button"
              className={`w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929]`}
              onClick={handleGenerateOtp}
            >
              Generate OTP
            </button>
          )}

          <button
            type="submit"
            className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929]"
            disabled={!otpSent}
          >
            Login
          </button>
        </form>

        <p className="text-center mt-4">
          <button onClick={handleForgotPassword} className="text-orange-500 ">
            Forgot Password?
          </button>
        </p>
      </div>
    </div>
  );
};

export default AdminLogin;
