import { useState, useEffect } from "react";
import axios from "axios";

const AddSubServiceHome = ({ children, serviceId }) => {
  const [subservices, setSubservices] = useState([]);

  useEffect(() => {
    const fetchSubservices = async () => {
      try {
        const response = await axios.get(
          `https://api.bhk99.com/api/subservices/subservices/${serviceId}`
        );
        setSubservices(response.data); // Save the subservices data
      } catch (error) {
        console.error("Error fetching subservices:", error);
      }
    };

    if (serviceId) {
      fetchSubservices();
    }
  }, [serviceId]);

  return children({ subservices });
};

export default AddSubServiceHome;
