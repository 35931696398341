import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AddPackage = ({ onPackageAdded = () => {} }) => {
  const [formData, setFormData] = useState({
    packageName: "",
    apartmentOwners: "",
    designDrawings: "",
    kitchen: "",
    bathroom: "",
    doors: "",
    painting: "",
    city: "", // New field for city
  });

  const handleQuillChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.bhk99.com/api/packages",
        formData
      );
      toast.success("Package added successfully!");
      onPackageAdded(response.data);
      setFormData({
        packageName: "",
        apartmentOwners: "",
        designDrawings: "",
        kitchen: "",
        bathroom: "",
        doors: "",
        painting: "",
        city: "", // Reset city field
      });
    } catch (error) {
      toast.error("Error adding package.");
      console.error(error);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-6 max-w-2xl mx-auto">
      <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">
        Add New Package
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-lg font-medium mb-2"
            htmlFor="packageName"
          >
            Package Name:
          </label>
          <ReactQuill
            value={formData.packageName}
            onChange={(value) => handleQuillChange("packageName", value)}
            theme="snow"
            className="bg-white rounded-md"
          />
        </div>

        {[
          "apartmentOwners",
          "designDrawings",
          "kitchen",
          "bathroom",
          "doors",
          "painting",
        ].map((field) => (
          <div className="mb-4" key={field}>
            <label className="block text-lg font-medium mb-2" htmlFor={field}>
              {field.charAt(0).toUpperCase() +
                field.slice(1).replace(/([A-Z])/g, " $1")}
              :
            </label>
            <ReactQuill
              value={formData[field]}
              onChange={(value) => handleQuillChange(field, value)}
              theme="snow"
              className="bg-white rounded-md"
            />
          </div>
        ))}

        <div className="mb-4">
          <label className="block text-lg font-medium mb-2" htmlFor="city">
            City:
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>

        <button
          className="bg-orange-500 text-white font-semibold rounded-md py-3 px-6 w-full hover:bg-orange-600 transition duration-300"
          type="submit"
        >
          Add Package
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddPackage;
