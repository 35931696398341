import { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { PiRuler } from "react-icons/pi";
import { IoUmbrellaOutline } from "react-icons/io5";
import { FaCar } from "react-icons/fa";
import { PiWall } from "react-icons/pi";
import { TbCurrencyRupee } from "react-icons/tb";
import axios from "axios";

export const HouseCalciCompTab = () => {
  const [pincode, setPincode] = useState("");
  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [balconyArea, setBalconyArea] = useState("");
  const [carParkingArea, setCarParkingArea] = useState("");
  const [boundaryArea, setBoundaryArea] = useState("");

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [result, setResult] = useState({
    superBuiltupCost: 0,
    balconyCost: 0,
    parkingCost: 0,
    boundaryCost: 0,
    totalCost: 0,
  });

  const [superBuiltupCostFactor, setSuperBuiltupCostFactor] = useState(1600);
  const [balconyCostFactor, setBalconyCostFactor] = useState(1325);
  const [parkingCostFactor, setParkingCostFactor] = useState(1340);
  const [boundaryCostFactor, setBoundaryCostFactor] = useState(1800);

  // Fetch the list of cities
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/houseCalci/get-cities"
        );
        setCities(response.data.data);
      } catch (error) {
        console.error("Error fetching cities", error);
      }
    };
    fetchCities();
  }, []);

  // Fetch cost factors for the selected city
  useEffect(() => {
    if (selectedCity) {
      const fetchCostFactorsForCity = async () => {
        try {
          const response = await axios.get(
            `https://api.bhk99.com/houseCalci/get-house-calci/${selectedCity}`
          );
          const { superBuiltupCost, balconyCost, parkingCost, boundaryCost } =
            response.data.data;
          setSuperBuiltupCostFactor(superBuiltupCost);
          setBalconyCostFactor(balconyCost);
          setParkingCostFactor(parkingCost);
          setBoundaryCostFactor(boundaryCost);
        } catch (error) {
          console.error("Error fetching cost factors for the city", error);
        }
      };
      fetchCostFactorsForCity();
    }
  }, [selectedCity]);

  const calculateCost = () => {
    const superBuiltupCost = superBuiltupArea * superBuiltupCostFactor;
    const balconyCost = balconyArea * balconyCostFactor;
    const parkingCost = carParkingArea * parkingCostFactor;
    const boundaryCost = boundaryArea * boundaryCostFactor;
    const totalCost =
      superBuiltupCost + balconyCost + parkingCost + boundaryCost;

    setResult({
      superBuiltupCost,
      balconyCost,
      parkingCost,
      boundaryCost,
      totalCost,
    });
  };

  return (
    <div className="w-full max-w-lg mx-auto p-4 rounded-lg shadow-md bg-[#FFF5EE] mt-[60px] mb-[40px]">
      <h2 className="text-2xl font-semibold mb-6 font-poppins text-center md:text-left">
        Cost Estimator
      </h2>

      {/* City Dropdown */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <FaLocationDot className="text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">City</label>
        </div>
        <select
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a city</option>
          {cities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>

      {/* Input Fields for Area */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <PiRuler className="text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Super Built-up Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={superBuiltupArea}
          onChange={(e) => setSuperBuiltupArea(e.target.value)}
          placeholder="Enter Super Built-up Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <IoUmbrellaOutline className="text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Balcony Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={balconyArea}
          onChange={(e) => setBalconyArea(e.target.value)}
          placeholder="Enter Balcony Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <FaCar className="text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Car Parking Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={carParkingArea}
          onChange={(e) => setCarParkingArea(e.target.value)}
          placeholder="Enter Car Parking Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <PiWall className="text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Boundary Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={boundaryArea}
          onChange={(e) => setBoundaryArea(e.target.value)}
          placeholder="Enter Boundary Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Calculate Button */}
      <button
        onClick={calculateCost}
        className="lg:w-[150px] font-poppins mt-6 px-4 py-2 text-white font-normal lg:text-sm rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
      >
        Calculate Cost
      </button>

      {/* Result Section */}
      <div className="mt-8 p-4 bg-[#ffe2d6] rounded-3xl shadow-md lg:w-[350px] mx-auto">
        <p className="font-poppins font-medium">
          Super Built-up Area Cost: <TbCurrencyRupee className="inline" />{" "}
          {result.superBuiltupCost}
        </p>
        <p className="font-poppins font-medium">
          Balcony Cost: <TbCurrencyRupee className="inline" />{" "}
          {result.balconyCost}
        </p>
        <p className="font-poppins font-medium">
          Parking Cost: <TbCurrencyRupee className="inline" />{" "}
          {result.parkingCost}
        </p>
        <p className="font-poppins font-medium">
          Boundary Cost: <TbCurrencyRupee className="inline" />{" "}
          {result.boundaryCost}
        </p>
        <p className="font-poppins font-semibold">
          Total Estimated Cost: <TbCurrencyRupee className="inline" />{" "}
          {result.totalCost}
        </p>
      </div>
    </div>
  );
};
