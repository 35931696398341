import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faChevronDown,
  faChevronUp,
  faHouseChimney,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong";
import Slider from "react-slick";
import axios from "axios";

const PricingMobile = () => {
  const [openFeature, setOpenFeature] = useState({});
  const [plans, setPlans] = useState([]);
  const [selectedCity, setSelectedCity] = useState("Banglore");
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();

  const toggleFeature = (planIndex, featureIndex) => {
    setOpenFeature((prevState) => ({
      ...prevState,
      [planIndex]: prevState[planIndex] === featureIndex ? null : featureIndex,
    }));
  };

  // Fetching packages based on the selected city
  const fetchPackages = async (city) => {
    try {
      const response = await axios.get("https://api.bhk99.com/api/packages", {
        params: { city },
      });
      const formattedPlans = response.data.map((pkg) => ({
        name: pkg.packageName.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags
        features: [
          { name: "Package details", description: pkg.apartmentOwners },
          { name: "Design and Drawings", description: pkg.designDrawings },
          { name: "Kitchen", description: pkg.kitchen },
          { name: "Bathroom", description: pkg.bathroom },
          { name: "Doors", description: pkg.doors },
          { name: "Painting", description: pkg.painting },
        ],
      }));
      setPlans(formattedPlans);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  // Fetch cities and packages when component mounts
  useEffect(() => {
    const fetchCitiesAndPackages = async () => {
      try {
        const citiesResponse = await axios.get(
          "https://api.bhk99.com/api/packages/cities"
        );
        setCities(citiesResponse.data);

        fetchPackages(citiesResponse.data[0]); // Default to first city
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCitiesAndPackages();
  }, []);

  // Handle city change and fetch new packages for the selected city
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    fetchPackages(event.target.value);
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <section className="py-8 bg-[#FFF5EE] mt-1">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-semibold font-poppins mb-4 text-center">
          Our Packages
        </h2>
        <span className="text-gray-500 text-sm font-poppins mb-6 block text-center">
          Find the best Home construction packages.
        </span>

        {/* City Selection for Mobile */}
        <div className="flex justify-center items-center mb-6">
          <p className="text-center text-[#FE6929] text-lg font-semibold mr-3">
            Please select your city to view available packages:
          </p>
          <select
            value={selectedCity}
            onChange={handleCityChange}
            className="p-3 border border-gray-300 rounded-lg text-gray-700"
          >
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        {/* Carousel for smaller screens */}
        <div className="block md:hidden">
          <Slider {...carouselSettings}>
            {plans.map((plan, planIndex) => {
              const isOpen = openFeature[planIndex] !== undefined;
              const tileHeight = isOpen ? "h-auto" : "h-[500px]";
              const tileWidth = "w-full";

              return (
                <div
                  key={planIndex}
                  className={`border border-gray-300 p-4 shadow-lg rounded-[45px] bg-white mx-auto ${tileWidth} ${tileHeight} relative`}
                >
                  <h2 className="bg-[#FE6929] w-full h-[60px] text-lg font-medium font-poppins mb-2 text-center text-white rounded-t-full flex justify-center items-center">
                    {plan.name}
                  </h2>

                  <div className="flex justify-center mb-4">
                    <FontAwesomeIcon
                      icon={faHouseChimney}
                      size="4x"
                      className="mt-4"
                    />
                  </div>
                  <ul className="space-y-3 list-disc pl-4 mt-4">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="text-gray-700">
                        <div className="flex justify-between items-center">
                          <span>{feature.name}</span>
                          <button
                            onClick={() =>
                              toggleFeature(planIndex, featureIndex)
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                openFeature[planIndex] === featureIndex
                                  ? faChevronUp
                                  : faChevronDown
                              }
                              className="text-[#FE6929]"
                            />
                          </button>
                        </div>
                        {openFeature[planIndex] === featureIndex && (
                          <p
                            className="mt-2 text-sm text-black p-2 rounded-lg"
                            style={{ backgroundColor: "#FFF5EE" }}
                            dangerouslySetInnerHTML={{
                              __html: feature.description,
                            }} // Render description as HTML
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => navigate("/ContactUs")}
                    className="mt-6 px-4 py-2 w-full text-[#FE6929] border border-[#FE6929] rounded-full hover:bg-[#FE6929] hover:text-white transition-colors duration-200"
                  >
                    Create Your Dream
                  </button>
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="flex justify-center mt-8">
          <button
            className="px-4 py-2 text-[#FE6929] bg-transparent border-none rounded-full font-poppins font-semibold hover:bg-[#FE6929] hover:text-white transition-colors duration-200"
            onClick={() => navigate("/compare-packages")}
          >
            Compare Packages <FontAwesomeIcon icon={faArrowRightLong} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default PricingMobile;
