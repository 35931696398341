import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobApplicationForm = ({ jobTitle, closeForm }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (!fullName || !email || !phone || !resume) {
      toast.error("Please fill all the required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("jobTitle", jobTitle);
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("resume", resume);

    try {
      await axios.post("https://api.bhk99.com/api/jobapplication", formData);
      console.log("Application submitted successfully");
      alert("Application submitted successfully!");
      handleCancel();
    } catch (error) {
      toast.error("Error submitting the application.");
    }
  };

  const handleCancel = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setResume(null);
    if (closeForm) closeForm(); // Close the modal if closeForm is passed
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-4">Apply for {jobTitle}</h2>
      <form onSubmit={handleSubmit} noValidate>
        <div className="mb-4">
          <label className="block font-medium text-gray-700">
            Full Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium text-gray-700">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            className="w-full p-2 border rounded"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium text-gray-700">
            Phone <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium text-gray-700">
            Resume <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            className="w-full p-2 border rounded"
            onChange={(e) => setResume(e.target.files[0])}
            required
          />
        </div>

        <div className="flex justify-end space-x-2">
          <button
            type="button"
            className="p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="p-2 bg-[#FE6929] text-white font-semibold rounded hover:bg-orange-600"
          >
            Submit
          </button>
        </div>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default JobApplicationForm;
