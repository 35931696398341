import { faHouse, faPerson } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong";
import { useNavigate } from "react-router-dom";

export const StatsForBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="px-2 py-4 lg:py-8 font-sans text-white ml-[-580px] md:ml-[0px] ml-[32px]">
      <div className="flex flex-wrap gap-x-4 gap-y-2 items-start justify-start max-w-3xl mx-auto md:ml-[40px] lg:ml-[80px]">
        <div className="flex flex-col items-start gap-0  mb-2 ml-[-50px]">
          <div>
            <h3 className="text-lg md:text-xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faHouse}
                className="mr-1 text-lg md:text-xl text-black"
              />
              <span className="text-[#FE6929] text-lg md:text-xl">3,298</span>
            </h3>
            <p className="text-black mt-1 text-xs"># of Buy Properties</p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-1 mb-2 ml-[-15px] md:ml-[10px]">
          <div>
            <h3 className="text-lg md:text-xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faHouse}
                className="mr-1 text-lg md:text-xl text-black"
              />
              <span className="text-[#FE6929] text-lg md:text-xl">2,181</span>
            </h3>
            <p className="text-black mt-1 text-xs"># of Sell Properties</p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-1 mb-2 ml-[-15px] md:ml-[10px]">
          <div>
            <h3 className="text-lg md:text-xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faHouse}
                className="mr-1 text-lg md:text-xl text-black"
              />
              <span className="text-[#FE6929] text-lg md:text-xl">9,316</span>
            </h3>
            <p className="text-black mt-1 text-xs"># of All Properties</p>
          </div>
        </div>
        <div className="flex flex-col items-start gap-1 mb-2 ml-[-31px] md:ml-[10px]">
          <div>
            <h3 className="text-lg md:text-xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faPerson}
                className="mr-1 text-lg md:text-xl text-black"
              />
              <span className="text-[#FE6929] text-lg md:text-xl">7,191</span>
            </h3>
            <p className="text-black mt-1 text-xs"># of Agents</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => navigate("/ContactUs")}
          className="bg-[#FE6929] text-white border-2 border-[#FE6929] py-2 px-4 rounded-full hover:bg-white hover:text-[#FE6929] transition-colors md:ml-[20px] mt-8 lg:ml-[-1150px] md:ml-[-610px] ml-10"
        >
          Let's Build <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
    </div>
  );
};
