import React, { useState, useEffect } from "react";

const FilterDiv = ({ onFiltersChange }) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [openDropdowns, setOpenDropdowns] = useState({
    title: false,
    cityName: false,
    propertyType: false,
    projectType: false,
    plotDimensions: false,
    houseBudget: false,
    roadFacing: false,
    numberOfFloors: false,
    typeOfConstruction: false,
    numberOfBedrooms: false,
    vastuCompliant: false,
  });
  const [filterOptions, setFilterOptions] = useState({
    title: ["1200 sqft", "1300 sqft", "1400 sqft"],
    cityName: [], // Dynamically fetched
    propertyType: [
      "Plot",
      "Studio",
      "Duplex",
      "Pent House",
      "Villa",
      "Apartments",
      "Independent House",
      "Builder Floor",
    ],
    projectType: [
      "Residential",
      "Commercial",
      "Construction",
      "Mixed-Use",
      "Design & Planning",
      "Other",
    ],
    plotDimensions: [
      "20x20 sqft",
      "20x30 sqft",
      "20x40 sqft",
      "20x50 sqft",
      "30x20 sqft",
      "30x30 sqft",
      "30x40 sqft",
      "30x50 sqft",
      "40x20 sqft",
      "40x30 sqft",
      "40x40 sqft",
      "40x50 sqft",
    ],
    roadFacing: ["East", "West", "North", "South"],
    numberOfFloors: ["G", "G+1", "G+2", "G+3"],
    typeOfConstruction: ["Brick", "Concrete", "Wood", "Steel"],
    numberOfBedrooms: ["1", "2", "3", "4+"],
  });

  useEffect(() => {
    // Fetch cities from the backend
    const fetchCities = async () => {
      try {
        const response = await fetch("https://api.bhk99.com/fetch/cities");
        const cities = await response.json();
        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          cityName: cities,
        }));
      } catch (error) {
        console.error("Failed to fetch cities:", error);
      }
    };

    fetchCities();
  }, []);

  const handleCheckboxChange = (filterCategory, filterValue) => {
    setSelectedFilters((prevFilters) => {
      const currentFilters = prevFilters[filterCategory] || [];
      const newFilters = currentFilters.includes(filterValue)
        ? currentFilters.filter((f) => f !== filterValue)
        : [...currentFilters, filterValue];

      const updatedFilters = {
        ...prevFilters,
        [filterCategory]: newFilters,
      };

      onFiltersChange(updatedFilters);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({});
    onFiltersChange({});
  };

  const toggleDropdown = (category) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <div className="bg-[#FFF5EE] w-full p-6 mt-5 rounded-lg flex flex-col space-y-6 shadow-lg max-w-[230px] mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold font-poppins text-[#333] mb-2">
          Filters ({Object.values(selectedFilters).flat().length})
        </h2>
        <button
          className="text-base font-bold font-poppins text-[#FE6929] hover:text-[#D85C29] transition-colors duration-200"
          onClick={clearFilters}
        >
          Clear All
        </button>
      </div>
      <hr className="border-gray-300 mb-6" />

      <div className="space-y-6">
        {Object.keys(filterOptions).map((category) => (
          <div key={category} className="space-y-4">
            <details
              open={openDropdowns[category]}
              onToggle={() => toggleDropdown(category)}
              className="cursor-pointer"
            >
              <summary
                className={`cursor-pointer font-semibold font-poppins text-lg ${
                  openDropdowns[category] ? "text-[#FE6929]" : "text-[#333]"
                }`}
              >
                {category.replace(/([A-Z])/g, " $1")}
              </summary>
              <div className="mt-2 space-y-3 bg-[#FFE2D6] p-4 rounded-lg">
                {filterOptions[category].map((option) => (
                  <label
                    key={option}
                    className="flex items-center space-x-2 hover:bg-[#FDD7C1] p-2 rounded-lg transition-colors duration-200"
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox text-[#FE6929] focus:ring-[#FE6929] mr-2"
                      onChange={() => handleCheckboxChange(category, option)}
                      checked={
                        selectedFilters[category]?.includes(option) || false
                      }
                    />
                    <span className="text-sm text-[#333]">{option}</span>
                  </label>
                ))}
              </div>
            </details>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterDiv;
