import React from "react";

export const Interior = ({ project }) => {
  const images = project.additionalImages || [];
  const projectdata = project?.projectDetails;

  console.log(images);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-white lg:mt-[15px] md:mt-[-320px] mt-[30px]">
      {/* Image Gallery */}
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 p-4 w-full max-w-4xl">
        {images.length > 0 ? (
          images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Image ${index + 1}`}
              className="lg:w-[240px] md:w-[150px] w-[100px] lg:h-[240px] md:h-[150px] h-[100px] object-cover"
            />
          ))
        ) : (
          <p className="text-center col-span-3">No images available</p>
        )}
      </div>

      {/* Project Data */}
      <div className="w-full max-w-4xl p-4 mt-4 mb-[-20px] text-left">
        <div
          className="text-gray-500 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: projectdata }} // Render HTML content
        />
      </div>
    </div>
  );
};
