import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";
import { FaBed, FaRoad } from "react-icons/fa";
import { GiBrickWall, GiCompass } from "react-icons/gi";

export const ThreeDTileMobile = ({ project }) => {
  if (!project) {
    return <div>Loading...</div>; // Fallback if no project data is found
  }
  return (
    <div className="bg-[#FFF5EE] p-4 rounded-2xl shadow-md mt-[40px] w-[360px] h-auto ml-[8px] mb-[20px]">
      {/* 3D Model Section */}
      <div className="relative pb-[56.25%]">
        <iframe
          src={project.threeDModelLink}
          frameBorder="0"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full rounded-t-2xl border-none"
        ></iframe>
      </div>

      {/* Like and Share Icons */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-2">
          <div className="bg-white text-white p-2 rounded-full ml-[14px]">
            <i className="fa fa-thumbs-up text-[#FE6929] text-xs"></i>
          </div>
          <p className="font-poppins text-[#FE6929] text-xs">49</p>
        </div>
        <div className="bg-white text-[#FE6929] p-2 rounded-full mr-[14px]">
          <i className="fa fa-share text-xs"></i>
        </div>
      </div>

      {/* Key Property Details */}
      <div className="flex justify-center gap-[40px] mt-4">
        <div>
          <HiOutlineBuildingOffice2 className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.plotDimensions}
          </p>
        </div>
        <div>
          <HiOutlineCurrencyRupee className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.houseBudget}
          </p>
        </div>
        <div>
          <GrSteps className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.numberOfFloors}
          </p>
        </div>
      </div>

      {/* Additional Details Section */}
      <div className="mt-6 p-4 bg-white rounded-md shadow-sm">
        <h3 className="font-bold font-poppins text-base text-[#FE6929] mb-4">
          Additional Details
        </h3>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center gap-2">
            <FaBed className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Bedrooms:{" "}
              <span className="font-bold">{project.numberOfBedrooms}</span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <FaRoad className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Road Facing:{" "}
              <span className="font-bold">{project.roadFacing}</span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <GiBrickWall className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Construction:{" "}
              <span className="font-bold">{project.typeOfConstruction}</span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <GiCompass className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Vastu Compliant:{" "}
              <span className="font-bold">
                {project.vastuCompliant ? "Yes" : "No"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
