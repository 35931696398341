import React from "react";
import AddBankingHome from "./Admin Panel/AddBankingHome"; // Import the logic for fetching data

const Banking = () => {
  return (
    <section className="py-12 md:py-24 bg-[#FFF5EE] flex justify-center items-center">
      <div className="container mx-auto py-10">
        {/* Title */}
        <div className="text-center  max-w-screen-lg mx-auto">
          <h2 className="text-2xl md:text-4xl font-semibold font-poppins mb-4 md:mb-8 ml-4 md:ml-16 lg:ml-0 text-center ">
            Our Banking Partners
          </h2>

          {/* Subtitle */}
          <span className="block text-gray-400 text-sm md:text-base font-poppins mb-4 ml-4 md:ml-16 lg:block text-center  lg:ml-[-5px]">
            Helping customers get easy access to home construction loans.
          </span>
        </div>

        {/* Images Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center md:ml-0 mt-12">
          {/* Render logos from database */}
          <AddBankingHome />
        </div>
      </div>
    </section>
  );
};

export default Banking;
