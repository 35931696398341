import React, { useState } from "react";
import {
  FaTachometerAlt,
  FaClipboardList,
  FaProjectDiagram,
  FaCalculator,
  FaSuitcase,
  FaEnvelopeOpenText,
  FaUsers,
  FaBuilding,
  FaInfoCircle,
} from "react-icons/fa";
import { RiSettings5Fill } from "react-icons/ri";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { IoNewspaper } from "react-icons/io5";
import { FaQ } from "react-icons/fa6";
import { MdAttachEmail } from "react-icons/md";
import { IoShareSocialSharp } from "react-icons/io5";
import { PiLinkSimpleLight } from "react-icons/pi";
import { GiKnightBanner } from "react-icons/gi";
import { FaRegImage } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";
import { SiLibreofficewriter } from "react-icons/si";

const Sidebar = ({ isOpen, toggleSidebar, setActiveSection }) => {
  const [isServiceSectionOpen, setIsServiceSectionOpen] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const [isProjectSectionOpen, setIsProjectSectionOpen] = useState(false);
  const [isCostEstimatorOpen, setIsCostEstimatorOpen] = useState(false);
  const [isPackagesSectionOpen, setIsPackagesSectionOpen] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isExtraSectionOpen, setIsExtraSectionOpen] = useState(false);
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const [isBankingPartnerOpen, setIsBankingPartnerOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isHeroOpen, setIsHeroOpen] = useState(false);
  const [isPWOpen, setIsPWOpen] = useState(false);
  const [isFooterInformationOpen, setFooterInformationOpen] = useState(false);
  const [isFooterGetInTouchOpen, setFooterGetInTouchOpen] = useState(false);
  const [isFooterSocialMediaOpen, setFooterSocialMediaOpen] = useState(false);
  const [isMorePageLinksOpen, setMorePageLinksOpen] = useState(false);
  const [isCarrerOpen, setCarrerOpen] = useState(false);

  return (
    <aside
      className={`bg-[#FE6929] text-white min-h-screen z-40 transition-transform transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:w-72 w-64 fixed md:static`}
    >
      {/* Close button for mobile view */}
      {isOpen && (
        <button
          className="absolute top-4 right-4 text-white"
          onClick={toggleSidebar}
        >
          <AiOutlineClose />
        </button>
      )}

      {/* Company Logo and Admin Panel Heading */}
      <div className="flex items-center p-6">
        <img
          src="/images/logo1.png"
          alt="Company Logo"
          className="w-16 h-16 object-contain mr-2 -mt-6"
        />
        <h1 className="text-xl font-bold text-center -mt-6">Admin Panel</h1>
      </div>

      {/* Navigation Items */}
      <nav className="mt-12">
        <ul className="space-y-8">
          {/* Dashboard */}
          <li className="flex items-center p-3 rounded hover:bg-[#ff8654] transition duration-200 cursor-pointer">
            <FaTachometerAlt className="text-xl mr-2" />
            <Link to="#" onClick={() => setActiveSection("dashboard")}>
              Dashboard
            </Link>
          </li>

          {/* Service Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsServiceSectionOpen(!isServiceSectionOpen)}
            >
              <div className="flex items-center">
                <FaClipboardList className="mr-2 text-xl" />
                <span>Service Section</span>
              </div>
              {isServiceSectionOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isServiceSectionOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("serviceManage");
                      toggleSidebar();
                    }}
                  >
                    Active Services
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("serviceForm");
                      toggleSidebar();
                    }}
                  >
                    Addition of Service
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddSubservices");
                      toggleSidebar();
                    }}
                  >
                    Addition of SubService
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveSubservices");
                      toggleSidebar();
                    }}
                  >
                    Active SubService
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {/* Our Projects Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsProjectSectionOpen(!isProjectSectionOpen)}
            >
              <div className="flex items-center">
                <FaProjectDiagram className="mr-2 text-xl" />
                <span>Our Projects</span>
              </div>
              {isProjectSectionOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isProjectSectionOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addProject");
                      toggleSidebar();
                    }}
                  >
                    Add a new project
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editProject");
                      toggleSidebar();
                    }}
                  >
                    Edit a project
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteProject");
                      toggleSidebar();
                    }}
                  >
                    Delete a project
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("projectTable");
                      toggleSidebar();
                    }}
                  >
                    Active Projects
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Cost Estimator Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsCostEstimatorOpen(!isCostEstimatorOpen)}
            >
              <div className="flex items-center">
                <FaCalculator className="mr-2 text-xl" />
                <span>Cost Estimator</span>
              </div>
              {isCostEstimatorOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isCostEstimatorOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("houseCalculator");
                      toggleSidebar();
                    }}
                  >
                    House Construction Calculator
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Other Navigation Items */}
          {/* Packages Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsPackagesSectionOpen(!isPackagesSectionOpen)}
            >
              <div className="flex items-center">
                <FaSuitcase className="mr-2 text-xl" />
                <span>Packages Section</span>
              </div>
              {isPackagesSectionOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isPackagesSectionOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActivePackages");
                      toggleSidebar();
                    }}
                  >
                    Active Packages
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddPackage");
                      toggleSidebar();
                    }}
                  >
                    Addition of Package
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Inbox Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsInboxOpen(!isInboxOpen)}
            >
              <div className="flex items-center">
                <FaEnvelopeOpenText className="mr-2 text-xl" />
                <span>Inbox</span>
              </div>
              {isInboxOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isInboxOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ContactUsAdmin");
                      toggleSidebar();
                    }}
                  >
                    Talk To Expert
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("TalkToExpert");
                      toggleSidebar();
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Users Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsAddUserOpen(!isAddUserOpen)}
            >
              <div className="flex items-center">
                <FaUsers className="mr-2 text-xl" />
                <span>Users</span>
              </div>
              {isAddUserOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isAddUserOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveUser");
                      toggleSidebar();
                    }}
                  >
                    Active Users
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddUser");
                      toggleSidebar();
                    }}
                  >
                    Add User
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsFAQOpen(!isFAQOpen)}
            >
              <div className="flex items-center">
                <FaQ className="mr-2 text-xl" />
                <span>FAQ</span>
              </div>
              {isFAQOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isFAQOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addFAQs");
                      toggleSidebar();
                    }}
                  >
                    Add FAQs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editFAQs");
                      toggleSidebar();
                    }}
                  >
                    Edit FAQs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteFAQs");
                      toggleSidebar();
                    }}
                  >
                    Delete FAQs
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsNewsOpen(!isNewsOpen)}
            >
              <div className="flex items-center">
                <IoNewspaper className="mr-2 text-xl" />
                <span>News</span>
              </div>
              {isNewsOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isNewsOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addNews");
                      toggleSidebar();
                    }}
                  >
                    Add News
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editNews");
                      toggleSidebar();
                    }}
                  >
                    Edit News
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteNews");
                      toggleSidebar();
                    }}
                  >
                    Delete News
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsExtraSectionOpen(!isExtraSectionOpen)}
            >
              <div className="flex items-center">
                <RiSettings5Fill className="mr-2 text-xl" />
                <span>Extras</span>
              </div>
              {isExtraSectionOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isExtraSectionOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addCustomerTestimonials");
                      toggleSidebar();
                    }}
                  >
                    Add Customer Testimonials
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteCustomerTestimonials");
                      toggleSidebar();
                    }}
                  >
                    Delete Customer Testimonials
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editCustomerTestimonials");
                      toggleSidebar();
                    }}
                  >
                    Edit Customer Testimonials
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsFAQOpen(!isFAQOpen)}
            >
              <div className="flex items-center">
                <FaQ className="mr-2 text-xl" />
                <span>FAQ</span>
              </div>
              {isFAQOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isFAQOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addFAQs");
                      toggleSidebar();
                    }}
                  >
                    Add FAQs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editFAQs");
                      toggleSidebar();
                    }}
                  >
                    Edit FAQs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteFAQs");
                      toggleSidebar();
                    }}
                  >
                    Delete FAQs
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsNewsOpen(!isNewsOpen)}
            >
              <div className="flex items-center">
                <IoNewspaper className="mr-2 text-xl" />
                <span>News</span>
              </div>
              {isNewsOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isNewsOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addNews");
                      toggleSidebar();
                    }}
                  >
                    Add News
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editNews");
                      toggleSidebar();
                    }}
                  >
                    Edit News
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteNews");
                      toggleSidebar();
                    }}
                  >
                    Delete News
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Banking Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsBankingPartnerOpen(!isBankingPartnerOpen)}
            >
              <div className="flex items-center">
                <FaBuilding className="mr-2 text-xl" />
                <span>Banking Partners</span>
              </div>
              {isBankingPartnerOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isBankingPartnerOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveBankingPartners");
                      toggleSidebar();
                    }}
                  >
                    Active Banking Partners
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddBankingPartner");
                      toggleSidebar();
                    }}
                  >
                    Add Banking Partner
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsBannerOpen(!isBannerOpen)}
            >
              <div className="flex items-center">
                <GiKnightBanner className="mr-2 text-xl" />
                <span>Banner</span>
              </div>
              {isBannerOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isBannerOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("homeBanner");
                      toggleSidebar();
                    }}
                  >
                    Home Banner
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("projectsBanner");
                      toggleSidebar();
                    }}
                  >
                    Projects Banner
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("3dBanner");
                      toggleSidebar();
                    }}
                  >
                    3D Floor Banner
                  </Link>
                </li>

                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("costBanner");
                      toggleSidebar();
                    }}
                  >
                    Cost Estimator Banner
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("hiwBanner");
                      toggleSidebar();
                    }}
                  >
                    How it Works Banner
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("contactBanner");
                      toggleSidebar();
                    }}
                  >
                    Contact Us Banner
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsHeroOpen(!isHeroOpen)}
            >
              <div className="flex items-center">
                <FaRegImage className="mr-2 text-xl" />
                <span>Hero Section</span>
              </div>
              {isHeroOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isHeroOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("homeHero");
                      toggleSidebar();
                    }}
                  >
                    Home Hero Image
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("projectsHero");
                      toggleSidebar();
                    }}
                  >
                    Projects Hero Image
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("3dHero");
                      toggleSidebar();
                    }}
                  >
                    3D Floor Hero Image
                  </Link>
                </li>

                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("costHero");
                      toggleSidebar();
                    }}
                  >
                    Cost Estimator Hero Image
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("hiwHero");
                      toggleSidebar();
                    }}
                  >
                    How it Works Hero Image
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("contactHero");
                      toggleSidebar();
                    }}
                  >
                    Contact Us Hero Image
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsPWOpen(!isPWOpen)}
            >
              <div className="flex items-center">
                <GrNotes className="mr-2 text-xl" />
                <span>Project Walkthrough</span>
              </div>
              {isPWOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isPWOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addPW");
                      toggleSidebar();
                    }}
                  >
                    Add
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editPW");
                      toggleSidebar();
                    }}
                  >
                    Edit
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deletePW");
                      toggleSidebar();
                    }}
                  >
                    Delete
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("activePW");
                      toggleSidebar();
                    }}
                  >
                    Active Walkthroughs
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Footer Information Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setFooterInformationOpen(!isFooterInformationOpen)}
            >
              <div className="flex items-center">
                <FaInfoCircle className="mr-2 text-xl" />
                <span>Footer Information</span>
              </div>
              {isAddUserOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isFooterInformationOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveTermsPolicy");
                      toggleSidebar();
                    }}
                  >
                    Active Terms and Policy
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddTermsPolicy");
                      toggleSidebar();
                    }}
                  >
                    Add Terms and Policy
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Footer Get in touch Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setFooterGetInTouchOpen(!isFooterGetInTouchOpen)}
            >
              <div className="flex items-center">
                <MdAttachEmail className="mr-2 text-xl" />
                <span>Footer Get in Touch</span>
              </div>
              {isFooterGetInTouchOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isFooterGetInTouchOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveEmail");
                      toggleSidebar();
                    }}
                  >
                    Active Email
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddEmail");
                      toggleSidebar();
                    }}
                  >
                    Add Email
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Footer Social media  Section with Dropdown */}
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setFooterSocialMediaOpen(!isFooterSocialMediaOpen)}
            >
              <div className="flex items-center">
                <IoShareSocialSharp className="mr-2 text-xl" />
                <span>Footer Social Media</span>
              </div>
              {isFooterSocialMediaOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isFooterSocialMediaOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveSocialMedia");
                      toggleSidebar();
                    }}
                  >
                    Active Social Media
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddSocialMedia");
                      toggleSidebar();
                    }}
                  >
                    Add Social Media
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setMorePageLinksOpen(!isMorePageLinksOpen)}
            >
              <div className="flex items-center">
                <PiLinkSimpleLight className="mr-2 text-xl" />
                <span>Mobile More button Links</span>
              </div>
              {isMorePageLinksOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isMorePageLinksOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveMorePageLinks");
                      toggleSidebar();
                    }}
                  >
                    Active More Page Links
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddMorePageLinks");
                      toggleSidebar();
                    }}
                  >
                    Add More Page Links
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setCarrerOpen(!isCarrerOpen)}
            >
              <div className="flex items-center">
                <SiLibreofficewriter className="mr-2 text-xl" />
                <span>Career</span>
              </div>
              {isCarrerOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isCarrerOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("AddJob");
                      toggleSidebar();
                    }}
                  >
                    Add Jobs
                  </Link>
                </li>

                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("ActiveJobListing");
                      toggleSidebar();
                    }}
                  >
                    Active Jobs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("JobApplications");
                      toggleSidebar();
                    }}
                  >
                    Jobs Applications
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
