import { useRef, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import AddSubServiceHome from "../Admin Panel/AddSubServiceHome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Subservices = () => {
  const scrollAmount = 300;
  const containerRef = useRef(null);
  const { serviceId } = useParams(); // Get the serviceId from URL
  const [subservices, setSubservices] = useState([]);

  const fetchSubservices = async () => {
    try {
      const response = await axios.get(
        `https://api.bhk99.com/api/subservices/subservices/${serviceId}`
      );
      setSubservices(response.data); // Set the subservices data
    } catch (error) {
      console.error("Error fetching subservices:", error);
    }
  };

  useEffect(() => {
    fetchSubservices(); // Fetch subservices when component mounts or serviceId changes
  }, [serviceId]);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      if (direction === "left") {
        containerRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: "smooth",
        });
      } else if (direction === "right") {
        containerRef.current.scrollTo({
          left: Math.min(scrollLeft + scrollAmount, scrollWidth - clientWidth),
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <AddSubServiceHome serviceId={serviceId}>
      {({ subservices }) => (
        <section className="py-28 bg-[#FFF5EE]">
          <div className="container mx-auto px-4 relative">
            <h2 className="text-4xl font-semibold font-poppins mb-8 text-center">
              Our Subservices
            </h2>
            <span className="text-gray-400 text-sm font-poppins mb-24 block text-center">
              Explore the subservices offered under this service. Our expert
              team is ready to assist you with all your real estate needs.
            </span>
            <div
              className="flex overflow-x-auto space-x-4 mt-16 scrollbar-hide"
              ref={containerRef}
            >
              {subservices.length > 0 ? (
                subservices.map((subservice) => {
                  const { _id, subServiceName, subServiceImage } = subservice;
                  return (
                    <div
                      key={_id}
                      className="flex-shrink-0 w-72 h-80 bg-white p-6 rounded-3xl shadow-lg transition-transform transform hover:scale-105 mb-6"
                    >
                      <div className="flex justify-center items-center w-36 h-36 overflow-hidden rounded-full mx-auto mb-4 border-4 border-gray-200 bg-gray-100">
                        <img
                          src={subServiceImage}
                          alt={subServiceName}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="text-center">
                        <h3 className="text-md font-semibold mb-2 font-poppins">
                          {subServiceName}
                        </h3>
                        <Link to={`/subservice/${_id}`}>
                          <button className="text-white bg-orange-500 hover:bg-orange-600 py-2 px-4 rounded-full">
                            See Details
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-center text-gray-500">
                  No subservices available.
                </p>
              )}
            </div>
            <div className="absolute inset-x-0 flex justify-center space-x-2 md:bottom-[-80px] lg:bottom-[-100px]">
              <button
                className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
                onClick={() => handleScroll("left")}
                aria-label="Scroll left"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-sm sm:text-lg"
                />
              </button>
              <button
                className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
                onClick={() => handleScroll("right")}
                aria-label="Scroll right"
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-sm sm:text-lg"
                />
              </button>
            </div>
          </div>
        </section>
      )}
    </AddSubServiceHome>
  );
};

export default Subservices;
