import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import InformationLinks from "../Admin Panel/InformationLinks"; // Import the new component
import { Link } from "react-router-dom";
import { GetInTouchEmail } from "../Admin Panel/GetInTouchEmail";
import { useNavigate } from "react-router-dom";

export const OurProjectFooter = () => {
  const [termsPolicyLink, setTermsPolicyLink] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all social media links from the backend
    const fetchSocialMediaLinks = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/social-media-links"
        );
        setSocialMediaLinks(response.data);
      } catch (error) {
        console.error("Error fetching social media links", error);
      }
    };

    fetchSocialMediaLinks();
  }, []);

  // Function to render the correct icon based on the platform name
  const renderIcon = (platform) => {
    switch (platform.toLowerCase()) {
      case "facebook":
        return (
          <FaFacebookF size={24} className="text-white hover:text-[#FE6929]" />
        );
      case "twitter":
        return (
          <FaTwitter size={24} className="text-white hover:text-[#FE6929]" />
        );
      case "linkedin":
        return (
          <FaLinkedinIn size={24} className="text-white hover:text-[#FE6929]" />
        );
      case "instagram":
        return (
          <FaInstagram size={24} className="text-white hover:text-[#FE6929]" />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchActiveTermsPolicy = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/terms-policy"
        );
        if (response.data.length > 0) {
          setTermsPolicyLink(response.data[0].link);
        }
      } catch (error) {
        console.error("Error fetching Terms and Policy");
      }
    };
    fetchActiveTermsPolicy();
  }, []);

  return (
    <>
      <footer className="font-sans tracking-wide bg-zinc-900 px-8 py-10 sm:py-20 md:py-24 lg:py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
          <div>
            <h4 className="text-white font-semibold text-lg relative cursor-pointer">
              Site Map
            </h4>
            <ul className="mt-6 space-y-4 text-gray-300 text-sm">
              {[
                <Link to="/HowItWorks">How it works</Link>,
                <Link to="/OurProjects">Our Projects</Link>,
                <Link to="/calci">Cost Estimator</Link>,
                <Link to="/3d-plans">Floor Plans</Link>,

                // "About Us",
                <Link to="/ContactUs">Contact Us</Link>,
                // "Reviews",
              ].map((item, index) => (
                <li key={index}>
                  <a href="javascript:void(0)" className="hover:text-[#FE6929]">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h4 className="text-white font-semibold text-lg relative cursor-pointer">
              Others
            </h4>
            <ul className="space-y-4 mt-6 text-gray-300 text-sm">
              {[
                "Join us as a professional",
                "Refer a Friend",
                "Channel Partner",
              ].map((item, index) => (
                <li key={index}>
                  <a href="javascript:void(0)" className="hover:text-[#FE6929]">
                    {item}
                  </a>
                </li>
              ))}
              <li>
                <h4 className="text-white font-semibold text-lg relative cursor-pointer mt-6">
                  Information
                </h4>
                <InformationLinks />{" "}
                {/* Render the dynamic Information links here */}
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-white font-semibold text-lg relative cursor-pointer">
              BHK99 Construction for Business
            </h4>
            <button
              onClick={() => navigate("/ContactUs")}
              className="mt-4 px-4 py-2 bg-transparent text-white border-2 border-transparent hover:border-[#FE6929] font-semibold hover:bg-[#FE6929] rounded-full flex items-center transition-colors"
            >
              BHK99 Building Materials
              <i className="fas fa-arrow-right ml-2"></i>
            </button>
            <div className="border-t border-gray-700 mt-6"></div>
            <h4 className="text-white font-semibold text-lg relative cursor-pointer mt-6">
              Get in Touch
            </h4>
            <GetInTouchEmail />{" "}
            {/* Replace static email with dynamic component */}
            <div className="border-t border-gray-700 mt-6"></div>
            <div className="flex flex-wrap gap-4 mt-6">
              <a
                href="https://play.google.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src="/images/playstore.png"
                  alt="Download on the Play Store"
                  className="lg:h-[103px] w-[135px] md:h-[60px] md:w-[150px]"
                />
              </a>
              <a
                href="https://apps.apple.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src="/images/apple.png"
                  alt="Download on the App Store"
                  className="lg:h-[45px] w-[125px] md:h-[60px] md:w-[150px]"
                />
              </a>
            </div>
            <div className="flex space-x-4 mt-6">
              {socialMediaLinks.map((link) => (
                <a
                  key={link._id}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.platform}
                >
                  {renderIcon(link.platform)}
                </a>
              ))}
              {/* {socialMediaLinks.twitter &&  (
              <a
                href={socialMediaLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-[#FE6929] transition-colors"
              >
                <FaTwitter size={24} />
              </a>
              )}
              {socialMediaLinks.facebook && (
              <a
                href={socialMediaLinks.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-[#FE6929] transition-colors"
              >
                <FaFacebookF size={24} />
              </a>
              )}
              {socialMediaLinks.linkedin && (
              <a
                href={socialMediaLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-[#FE6929] transition-colors"
              >
                <FaLinkedinIn size={24} />
              </a>
              )}
              {socialMediaLinks.instagram && (
              <a
                href={socialMediaLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-[#FE6929] transition-colors"
              >
                <FaInstagram size={24} />
              </a>
              )} */}
            </div>
          </div>
        </div>
        <div className="border-t border-white mt-10"></div>
        <a
          href="https://braise.in/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-[#FE6929] text-gray-300 text-sm mt-6 text-center block"
        >
          &copy; 2024 Braise Tech Solutions Pvt.Ltd. All Rights Reserved.
        </a>
      </footer>
    </>
  );
};
