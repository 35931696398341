import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FilterDivMobile from "./Our Project/FilterDivMobile";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export const ProjectMobile = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const containerRef = useRef(null);
  const scrollAmount = 200;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetch/projectsHome"
        );
        setProjects([...response.data]);
        setFilteredProjects([...response.data]);
      } catch (error) {
        console.error("Error fetching the projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      if (direction === "left") {
        containerRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: "smooth",
        });
      } else if (direction === "right") {
        const newScrollLeft = scrollLeft + scrollAmount;
        if (newScrollLeft >= scrollWidth - clientWidth) {
          setTimeout(() => {
            containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
          }, 300);
        } else {
          containerRef.current.scrollTo({
            left: newScrollLeft,
            behavior: "smooth",
          });
        }
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleScroll("right");
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const handleFiltersChange = (filters) => {
    if (Object.values(filters).every((filter) => filter.length === 0)) {
      setFilteredProjects(projects);
      return;
    }

    const filtered = projects.filter((project) => {
      const matchesTitle =
        !filters.title || filters.title.includes(project.title);
      const matchesCity =
        !filters.cityName || filters.cityName.includes(project.cityName);
      const matchesPropertyType =
        !filters.propertyType ||
        filters.propertyType.includes(project.propertyType);
      const matchesProjectType =
        !filters.projectType ||
        filters.projectType.includes(project.projectType);

      return (
        matchesTitle && matchesCity && matchesPropertyType && matchesProjectType
      );
    });

    setFilteredProjects(filtered);
  };

  return (
    <section className="py-20 bg-[#FFFFFF] relative">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold font-poppins mb-3 text-center">
          Our Projects
        </h2>
        <span className="text-gray-400 text-sm font-poppins mb-8 block text-center">
          Find the perfect place that suits your needs and preferences.
        </span>

        <FilterDivMobile onFiltersChange={handleFiltersChange} />

        <div className="relative mt-4">
          <div
            ref={containerRef}
            className="flex space-x-4 overflow-x-auto snap-x snap-mandatory touch-pan-x scroll-smooth mt-5"
          >
            {filteredProjects.map((project, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-[220px] sm:w-[180px] h-[220px] bg-white shadow-md rounded-3xl snap-center"
              >
                <img
                  src={project.propertyImage || "images/placeholder.png"}
                  alt={project.title}
                  className="w-full h-2/3 object-cover rounded-t-3xl"
                />
                <div className="p-4 mt-[-5px] flex flex-col justify-between h-1/3">
                  <h3 className="text-xs font-semibold text-[#FE6929]">
                    {project.title}
                  </h3>
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-extrabold text-gray-700">
                      {project.cityName}
                    </p>

                    <Link to={`/project2/${project._id}`}>
                      <button className="px-3 py-1 border border-[#FE6929] text-[#FE6929] text-xs font-semibold rounded-full hover:bg-[#FE6929] hover:text-white transition-colors duration-300">
                        See Details
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="absolute inset-x-0 flex justify-center space-x-2 bottom-[-60px]">
            <button
              className="bg-[#FE6929] text-white p-2 rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => handleScroll("left")}
              aria-label="Scroll left"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="text-sm" />
            </button>
            <button
              className="bg-[#FE6929] text-white p-2 rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => handleScroll("right")}
              aria-label="Scroll right"
            >
              <FontAwesomeIcon icon={faArrowRight} className="text-sm" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
