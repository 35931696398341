import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useJobDetailsData } from "./Admin Panel/AddJobDetailsHome";
import JobApplicationForm from "./JobApplicationForm";
import { Footer } from "./Footer";
import { Navbar2 } from "./Navbar2";
import { NavbarTab } from "./NavbarTab";
import NavbarMobile from "./NavbarMobile";

const JobDetails = () => {
  const { id } = useParams();
  const { jobDetails, loading } = useJobDetailsData(id);
  const [showForm, setShowForm] = useState(false);
  const [viewport, setViewport] = useState("large");

  // Effect hook to handle viewport changes
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array ensures this effect runs only once after the first render

  if (loading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  if (!jobDetails) {
    return <div className="p-6 text-center text-red-500">Job not found</div>;
  }

  const { title, description } = jobDetails;

  return (
    <div className="bg-gray-100 min-h-screen">
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}

      {/* Job details card with responsive width and padding */}
      <div className="pb-12">
        <div className="max-w-7xl mx-auto bg-white p-8 shadow-lg rounded-lg lg:mt-12 md:mt-12">
          <h1 className="text-3xl font-bold mb-4 text-gray-800">{title}</h1>
          {/* Use React Quill-rendered description */}
          <div
            className="text-gray-600 mb-6"
            dangerouslySetInnerHTML={{
              __html: description.replace(
                /<ul>/g,
                '<ul class="list-disc pl-8">'
              ),
            }}
          ></div>

          {/* Apply Button */}
          <button
            onClick={() => setShowForm(true)}
            className="px-6 py-2 bg-[#FE6929] text-white font-semibold rounded-lg hover:bg-orange-600"
          >
            Apply
          </button>
        </div>
      </div>

      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
            <JobApplicationForm
              jobTitle={title}
              closeForm={() => setShowForm(false)}
            />
            <button
              onClick={() => setShowForm(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              ✖
            </button>
          </div>
        </div>
      )}

      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </div>
  );
};

export default JobDetails;
