import { useRef } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AddServiceHome from "./Admin Panel/AddServiceHome";
import { Link } from "react-router-dom";

const OurServices = () => {
  const scrollAmount = 300;
  const containerRef = useRef(null);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      if (direction === "left") {
        containerRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: "smooth",
        });
      } else if (direction === "right") {
        containerRef.current.scrollTo({
          left: Math.min(scrollLeft + scrollAmount, scrollWidth - clientWidth),
          behavior: "smooth",
        });
      }
    }
  };

  const isBase64 = (str) => {
    const pattern = /^data:image\/[a-zA-Z]+;base64,/;
    return pattern.test(str);
  };

  return (
    <AddServiceHome>
      {({ services }) => (
        <section className="py-28 bg-[#FFF5EE]">
          <div className="container mx-auto px-4 relative">
            <h2 className="text-4xl font-semibold font-poppins mb-8 text-center sm:text-left sm:ml-10 lg:text-center lg:ml-0">
              Our Services
            </h2>

            <span className="text-gray-400 text-sm font-poppins mb-24 block text-center lg:text-center sm:text-left">
              Expert guidance and tailored services and solutions for all your
              real estate needs.
            </span>

            <div
              className="flex overflow-x-auto space-x-4 mt-16 scrollbar-hide"
              ref={containerRef}
            >
              {services.map((service) => {
                const imageUrl = isBase64(service.image)
                  ? service.image
                  : `data:image/jpeg;base64,${service.image}`;

                return (
                  <div
                    key={service._id}
                    className="flex-shrink-0 w-72 h-80 bg-white p-6 rounded-3xl shadow-lg transition-transform transform hover:scale-105 mb-6"
                  >
                    <div className="flex justify-center items-center w-36 h-36 overflow-hidden rounded-full mx-auto mb-4 border-4 border-gray-200 bg-gray-100">
                      <img
                        src={imageUrl}
                        alt={service.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="text-center">
                      <h3 className="text-md font-semibold mb-2 font-poppins">
                        {service.name}
                      </h3>
                      <Link to={`/subservices/${service._id}`}>
                        <button className="text-white bg-orange-500 hover:bg-orange-600 py-2 px-4 rounded-full">
                          See Details
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="absolute inset-x-0 flex justify-center sm:justify-start lg:justify-center space-x-2 md:bottom-[-80px] lg:bottom-[-100px]">
              <button
                className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
                onClick={() => handleScroll("left")}
                aria-label="Scroll left"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-sm sm:text-lg"
                />
              </button>
              <button
                className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
                onClick={() => handleScroll("right")}
                aria-label="Scroll right"
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-sm sm:text-lg"
                />
              </button>
            </div>
          </div>
        </section>
      )}
    </AddServiceHome>
  );
};

export default OurServices;
