import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FilterDiv from "./FilterDiv";

const OurProjectProjectsSection = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetch/projects"
        );

        if (Array.isArray(response.data)) {
          setProjects(response.data);
          setFilteredProjects(response.data);
        } else {
          console.error("API response is not an array", response.data);
          setProjects([]);
          setFilteredProjects([]);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const handleFiltersChange = (filters) => {
    if (Object.values(filters).every((filter) => filter.length === 0)) {
      setFilteredProjects(projects);
      return;
    }

    const filtered = projects.filter((project) => {
      const matchesTitle =
        !filters.title ||
        filters.title.length === 0 ||
        filters.title.includes(project.title);
      const matchesCity =
        !filters.cityName ||
        filters.cityName.length === 0 ||
        filters.cityName.includes(project.cityName);
      const matchesPropertyType =
        !filters.propertyType ||
        filters.propertyType.length === 0 ||
        filters.propertyType.includes(project.propertyType);
      const matchesProjectType =
        !filters.projectType ||
        filters.projectType.length === 0 ||
        filters.projectType.includes(project.projectType);
      const matchesPlotDimensions =
        !filters.plotDimensions ||
        filters.plotDimensions.length === 0 ||
        filters.plotDimensions.includes(project.plotDimensions);

      const matchesRoadFacing =
        !filters.roadFacing ||
        filters.roadFacing.length === 0 ||
        filters.roadFacing.includes(project.roadFacing);

      const matchesNumberOfFloors =
        !filters.numberOfFloors ||
        filters.numberOfFloors.length === 0 ||
        filters.numberOfFloors.includes(project.numberOfFloors);

      const matchesTypeOfConstruction =
        !filters.typeOfConstruction ||
        filters.typeOfConstruction.length === 0 ||
        filters.typeOfConstruction.includes(project.typeOfConstruction);

      const matchesNumberOfBedrooms =
        !filters.numberOfBedrooms ||
        filters.numberOfBedrooms.length === 0 ||
        filters.numberOfBedrooms.includes(project.numberOfBedrooms);

      return (
        matchesTitle &&
        matchesCity &&
        matchesPropertyType &&
        matchesProjectType &&
        matchesPlotDimensions &&
        matchesRoadFacing &&
        matchesNumberOfFloors &&
        matchesTypeOfConstruction &&
        matchesNumberOfBedrooms
      );
    });

    setFilteredProjects(filtered);
    setCurrentPage(1);
  };

  // Pagination calculations
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = Array.isArray(filteredProjects)
    ? filteredProjects.slice(indexOfFirstProject, indexOfLastProject)
    : [];

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <section className="py-24 bg-[#FFFFFF] relative">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-semibold font-poppins mb-3 text-center">
          Our Projects
        </h2>

        <span className="text-gray-400 text-sm font-poppins mb-24 block text-center">
          Explore how our works have changed the lives of customers
        </span>

        {/* Main Flex Container */}
        <div className="flex flex-wrap gap-8 justify-between">
          {/* Filter Section - positioned to the left */}
          <div className="w-full md:w-1/4 lg:w-1/5 xl:w-1/6 flex-shrink-0">
            <FilterDiv onFiltersChange={handleFiltersChange} />
          </div>

          {/* Projects Grid - takes up the remaining space */}
          <div className="w-full md:w-3/4 lg:w-4/5 xl:w-5/6 lg:ml-[270px] lg:mt-[-650px] md:ml-[200px] md:mt-[-815px]">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8 lg:gap-16 mt-4">
              {currentProjects.map((project, index) => (
                <div key={index} className="bg-white shadow-xl rounded-3xl">
                  <img
                    src={project.propertyImage}
                    alt={project.title}
                    className="w-full h-[400px] object-cover rounded-t-3xl"
                  />
                  <div className="p-6 flex justify-between">
                    <div className="flex flex-col justify-between">
                      <h3 className="text-lg font-semibold text-[#FE6929]">
                        {project.title}
                      </h3>
                      <p className="text-xl font-extrabold text-black-600">
                        {project.cityName}
                      </p>
                    </div>
                    <div className="flex flex-col justify-between items-end">
                      <p className="text-sm font-extrabold text-gray-400">
                        {project.propertyType}
                      </p>
                      <button
                        className="px-3 py-1 border border-[#FE6929] bg-[#FE6929] text-white rounded-full text-sm font-semibold"
                        onClick={() =>
                          navigate("/project", { state: { project } })
                        }
                      >
                        See Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination controls */}
            <div className="flex justify-center mt-6 space-x-4">
              <button
                onClick={handlePrevPage}
                className={`px-4 py-2 border ${
                  currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer"
                } border-[#FE6929] bg-[#FE6929] text-white rounded-full`}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                onClick={handleNextPage}
                className={`px-4 py-2 border ${
                  currentPage === totalPages
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } border-[#FE6929] bg-[#FE6929] text-white rounded-full`}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProjectProjectsSection;
