import React from "react";
import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";
import { FaBed, FaRoad } from "react-icons/fa";
import { GiBrickWall, GiCompass } from "react-icons/gi";

export const ThreeDTileMobileHome = ({ project }) => {
  if (!project) {
    return <div>Loading...</div>; // Fallback if no project data is found
  }

  return (
    <div className="bg-[#FFF5EE] p-1 rounded-2xl shadow-md w-[360px] h-[450px] mb-[20px] ml-[8px] mt-[40px]">
      <div className="relative pb-[56.25%]">
        <iframe
          src={project.threeDModelLink}
          className="absolute top-0 left-0 rounded-t-2xl border-none w-[340px] h-[270px] ml-[10px] mt-[10px]"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <div className="flex justify-between items-center mt-[115px]">
        <div className="flex items-center gap-2 mt-2">
          <div className="bg-white text-white p-1 rounded-full ml-[14px]">
            <i className="fa fa-thumbs-up text-[#FE6929] text-xs"></i>
          </div>
          <p className="font-poppins text-[#FE6929] text-xs">49</p>
        </div>
        <div className="bg-white text-[#FE6929] p-1 rounded-full mr-[14px] mt-2">
          <i className="fa fa-share text-xs"></i>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-y-4 justify-center mt-[5px]">
        <div className="flex flex-col items-center">
          <HiOutlineBuildingOffice2 className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.plotDimensions}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <HiOutlineCurrencyRupee className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.houseBudget}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <GrSteps className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.numberOfFloors}
          </p>
        </div>
      </div>
      {/* Additional Details Section */}
      <div className="mt-16 p-4 bg-white rounded-md shadow-sm">
        <h3 className="font-bold font-poppins text-base text-[#FE6929] mb-4">
          Additional Details
        </h3>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center gap-2">
            <FaBed className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Bedrooms:{" "}
              <span className="font-bold">{project.numberOfBedrooms}</span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <FaRoad className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Road Facing:{" "}
              <span className="font-bold">{project.roadFacing}</span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <GiBrickWall className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Construction:{" "}
              <span className="font-bold">{project.typeOfConstruction}</span>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <GiCompass className="text-[#FE6929]" />
            <p className="font-poppins text-xs">
              Vastu Compliant:{" "}
              <span className="font-bold">
                {project.vastuCompliant ? "Yes" : "No"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
